<div class="mt-4 mb-2 box">
    <div class="imgBx">
        <img loading="lazy" src="{{foto}}" alt="Foto do(a) {{nome}}">
    </div>
    <ul class="social-icon">
        <li *ngIf="email"><a href="mailto:{{email}}"><ion-icon name="mail-outline"></ion-icon></a></li>
        <li *ngIf="linkedin"><a [href]="linkedin" target="_blank"><ion-icon name="logo-linkedin"></ion-icon></a></li>
    </ul>
    <div class="details">
        <h2>{{nome}}<br><span class="mt-1 d-flex justify-content-center">{{cargo}}</span></h2>  
    </div>                              
</div> 