<div>
    <app-header></app-header>
    <div class="headerHeight"></div>

    <div class="content" [formGroup]="form">

        <!--  -->
        <section class="p1">
            <div class="p1i d-flex w-100">
                <div class="container">
                    <div class="row h-100">
                        <div class="d-none d-lg-flex col-lg-6 h-100" style="align-items: center;">
                            <img src="/assets/images/q1-showcase-570px.webp" style="width: 100%; max-width: 570px;" alt="Imagem mostrando o sistema Quality1">
                        </div>
                        <div class="col-sm-12 col-lg-6 h-100 d-flex" style="justify-content: center; flex-direction: column;">
                            <h1 class="p1Title" style="margin: 0;">Elabore FMEA, Plano de Controle e MSA de forma simples e com segurança</h1>
                            <h2 style="margin-bottom: 0;" class="p1SubTitle">Faça a Gestão online de PPAP com <br> LGPD Compliance</h2>
                            <div class="d-flex w-100 justify-content-end" style="margin-top: 80px;">
                                <div class="btnGreen" style="margin-right: 13px;">
                                    <div class="icon"><ion-icon name="lock-closed"></ion-icon></div>
                                    <h3 style="font-size: 20px;">100% Seguro</h3>
                                </div>
                                <div class="btnGreen" (click)="goSection(5)">
                                    <div class="icon" ><ion-icon name="mail"></ion-icon></div>
                                    <h3>Fale conosco</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!--  -->
        <section class="p2">

            <div style="margin-top: -90px;">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-4">
                            <div class="cardP2">
                                <div class="w-100 d-flex justify-content-center">
                                    <img loading="lazy" src="/assets/images/icon-1-100px.webp" height="100" width="100" alt="Representação que o Quality1 é totalmente Online">
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <h3 class="cardP2Title">Totalmente Online</h3>
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <h4 class="cardP2SubTitle">Dispensa investimentos em infra e capacitação de profissionais para manutenção do sistema</h4>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-4">
                            <div class="cardP2">
                                <div class="w-100 d-flex justify-content-center">
                                    <!-- <img loading="lazy" src="/assets/images/icon-2-100px.webp" height="100" width="100" alt="Representação que o Quality1 é 100% integrado"> -->
                                    <span class="css-sprite-icon-2-100px"></span>
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <h3 class="cardP2Title">100% Integrado</h3>
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <h4 class="cardP2SubTitle">Informações compartilhadas e totalmente integradas entre Fluxo de Processo, PFMEA e Plano de Controle</h4>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-4">
                            <div class="cardP2">
                                <div class="w-100 d-flex justify-content-center">
                                    <!-- <img loading="lazy" src="/assets/images/icon-3-100px.webp" height="100" width="100" alt="Representação que o Quality1 é sempre atualizado"> -->
                                    <span class="css-sprite-icon-3-100px"></span>
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <h3 class="cardP2Title">Sempre Atualizado</h3>
                                </div>
                                <div class="w-100 d-flex justify-content-center">
                                    <h4 class="cardP2SubTitle">Atendimento as normas técnicascomo IATF 16949, ISO 9001 e ISO/IEC 27001</h4>
                                </div>
                                 <div #partWhat></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="position: relative;">
                <div id="oque" style="position: absolute; margin-top: -50px;"></div>
            </div>
            <h2 class="p2Title mb-0">O que é o QualityManager ®?</h2>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <div class="blue-line">
                    <span></span>
                </div>
            </div>
            <span class="p2SubTitle container">Entenda como o QualityManager ® vai aprimorar a gestão do seu processo</span>

            <div class="container mt-5">
                <div class="row">
                    <div class="col-12 col-lg-6 d-none d-lg-flex">
                        <!-- <video alt="Q1.App system" autoplay loop>
                            <source src="/assets/images/Login-QM-sm.webm" type="video/webm">
                        </video> -->
                        <img src="/assets/images/Mockup-Q1.png" width="683" height="340" loading="lazy" alt="Mockup do sistema Quality1 em um notebook">
                    </div>
                    <p class="col-12 col-lg-6 p2text">
                        Lançado em 2005, o <b>QualityManager ®</b> foi o primeiro software 100% web para gestão da qualidade e que tem por objetivo gerenciar, elaborar e armazenar todos os dados requeridos das ferramentas da Qualidade exigidas pela IATF, como o Planejamento Avançado da Qualidade do Produto (APQP), o Processo de Aprovação de Peça de Produção (PPAP), a Análise de Modo e Efeitos de Falha Potencial (FMEA), o Controle Estatístico do Processo (CEP) e a Análise dos Sistemas de Medição (MSA).
                    </p>
                </div>
            </div>

            <div #partDif></div>
        </section>

        <!--  -->
        <div style="position: relative;">
            <div id="diferenciais" style="position: absolute; margin-top: -50px;"></div>
        </div>
        <section class="pGray">
            <div style="height: 1px;"></div>

            <span class="p2Title">Diferenciais</span>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <div class="blue-line">
                    <span></span>
                </div>
            </div>
            <span class="p2SubTitle container">Confira algumas características que tornam o QualityManager ® único.</span>
            <br>

            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-4 mt-4">

                        <div class="d-flex mt-5 jE">
                            <div class="difItem1">
                                <span class="difItemTitle tR">LGPD Compliance</span>
                                <span class="difItemText tR">Aplicação totalmente em conformidade com a LGPD - Lei Geral de Proteção de Dados.</span>
                            </div>
                            <div class="difItem2">
                                <!-- <img loading="lazy" class="difItemImg" width="53" height="60" src="/assets/icon/feature/implantacao-rapida.webp" alt="Icone simbolizando o LGPD Center"> -->
                                <span class="css-sprite-implantacao-rapida"></span>
                            </div>
                        </div>

                        <div class="d-flex jE">
                            <div class="difItem1">
                                <span class="difItemTitle tR">Cyber Security</span>
                                <span class="difItemText tR">Infraestrutura baseada em segurança em compliance com família ISO 27000.</span>
                            </div>
                            <div class="difItem2">
                                <!-- <img loading="lazy" class="difItemImg" width="53" height="60" src="/assets/icon/feature/sem-limite-usuarios.webp" alt="Icone simbolizando o Cyber Security"> -->
                                <span class="css-sprite-sem-limite-usuarios"></span>
                            </div>
                        </div>


                        <div class="d-flex jE">
                            <div class="difItem1">
                                <span class="difItemTitle tR">100% Customizável</span>
                                <span class="difItemText tR">Capacidade de agregar novos conceitos, melhorias e alterações.</span>
                            </div>
                            <div class="difItem2">
                                <!-- <img loading="lazy" class="difItemImg" width="53" height="60" src="/assets/icon/feature/suporte-integral.webp" alt="Imagem simbolizando que o Quality1 tem suporte integral"> -->
                                 <span class="css-sprite-suporte-integral"></span>
                            </div>
                        </div>


                    </div>

                    <div class="col-12 col-md-12 col-lg-4" style="display: flex; justify-content: center;">
                        <img loading="lazy" width="350" height="644" src="/assets/images/impressoes.webp" alt="Imagem mostrando papeis que foram imprimidos">
                    </div>

                    <div class="col-12 col-md-12 col-lg-4 mt-4">

                        <div class="d-flex mt-5">
                            <div class="difItem2">
                                <!-- <img loading="lazy" class="difItemImg" width="53" height="60" src="/assets/icon/feature/customizavel.webp" alt="Imagem simbolizando que o Quality1 é customizavel"> -->
                                <span class="css-sprite-customizavel"></span>
                            </div>
                            <div class="difItem1">
                                <span class="difItemTitle tL">Suporte</span>
                                <span class="difItemText tL">Atendimento diferenciado, updates normativos e patches sem custos adicionais.</span>
                            </div>
                        </div>

                        <div class="d-flex">
                            <div class="difItem2">
                                <!-- <img loading="lazy" class="difItemImg" width="53" height="60" src="/assets/icon/feature/escalavel.webp" alt="Imagem simbolizando que o Quality1 é escalavel"> -->
                                <span class="css-sprite-escalavel"></span>
                            </div>
                            <div class="difItem1">
                                <span class="difItemTitle tL">Treinamentos</span>
                                <span class="difItemText tL">Oferecemos uma plataforma própria em EAD com certificado de conclusão.</span>
                            </div>
                        </div>

                        <div class="d-flex">
                            <div class="difItem2">
                                <!-- <img loading="lazy" class="difItemImg" width="53" height="60" src="/assets/icon/feature/backups.webp" alt="Imagem simbolizando backups"> -->
                                <span class="css-sprite-backups"></span>
                            </div>
                            <div class="difItem1">
                                <span class="difItemTitle tL">Acessibilidade</span>
                                <span class="difItemText tL">Acesso rápido, fácil e seguro, sem necessidade de instalação.</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>

        <!--  -->
        <section class="pBlue">
            <div class="pBluei">
                <div class="container h-100">
                    <div class="row h-100" style="display: flex; align-items: center;">
                        <div class="col-12 col-md-8" style="display: flex; flex-direction: column; justify-content: center;">
                            <h2 class="pBlueTitle">Nós estamos <br> à disposição</h2>
                            <h2 class="pBlueSubTitle mt-4 mb-0">Fale com nossos especialistas em Qualidade, segurança e proteção de dados. Pergunte, tire suas dúvidas e conheça nosso time!</h2>
                        </div>
                        <div class="col-12 col-md-4"  style="display: flex; flex-direction: column; justify-content: center;">
                            <br class="d-flex d-md-none">
                            <br class="d-flex d-md-none">
                            <button (click)="goSection(5)" class="btnPBlue">Fale Conosco</button>
                        </div>
                    </div>
                </div>
                <div #partQ></div>
            </div>
        </section>

        <!--  -->
        <div style="position: relative;">
            <div id="porque" style="position: absolute; margin-top: -50px;"></div>
        </div>
        <section class="pWhite">
            <div style="height: 1px;"></div>

            <h2 class="p2Title mb-0">Por que o QualityManager ®?</h2>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <!-- <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="Divisoria de conteudo"> -->
                <div class="blue-line">
                    <span></span>
                </div>
            </div>
            <span class="p2SubTitle container">Vamos aos fatos... Você já passou por esses problemas?</span>

            <div class="container mt-5">
                <div class="row">
                    <div class="col-12 col-md-6">

                        <div>
                            <h2 class="whyTitle"><ion-icon name="checkmark"></ion-icon> As planilhas não atendem mais</h2>
                            <h4 class="whySubTitle">Além de permitir o manuseio indiscriminado dos dados, as planilhas não garantem os vínculos exigidos pela norma. No QualityManager ®, as informações entre Fluxo, FMEA e Plano de Controle são <b>totalmente interligadas.</b></h4>
                        </div>

                        <div>
                            <h2 class="whyTitle"><ion-icon name="checkmark"></ion-icon> Comunicação e integração do Time</h2>
                            <h4 class="whySubTitle">Baseado em um fluxo gerenciável de notificações, o sistema informa o andamento e prazos a todos os envolvidos do processo. Além disso, também é possível que vários usuários trabalhem dentro de um mesmo item.</h4>
                        </div>

                    </div>
                    <div class="col-12 col-md-6">

                        <div>
                            <h2 class="whyTitle"><ion-icon name="checkmark"></ion-icon> Retenção de conhecimento</h2>
                            <h4 class="whySubTitle">Pensando na rotatividade de pessoal dentro do time, disponibilizamos a capacitação de usuários de forma on-line, através de treinamentos no modelo Ensino à Distância (EAD), disponíveis por tempo ilimitado.</h4>
                        </div>

                        <div>
                            <h2 class="whyTitle"><ion-icon name="checkmark"></ion-icon> Foco no que é importante</h2>
                            <h4 class="whySubTitle">Tenha mais tempo para dedicar-se a Qualidade do seu processo, deixando a burocracia, problemas técnicos, backups, disponibilidade de informações, cálculos e fórmulas por nossa conta.</h4>
                        </div>

                    </div>
                </div>
                <div #partPlans></div>
            </div>

        </section>

        <!--  -->
        <div style="position: relative;">
            <div id="planos" style="position: absolute; margin-top: -50px;"></div>
        </div>
        <section class="pGray">
            <div style="height: 1px;"></div>

            <h2 class="p2Title mb-0">Planos</h2>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <div class="blue-line">
                    <span></span>
                </div>
            </div>
            <h2 class="p2SubTitle">Pensado em escalabilidade, nossos planos são adequados a qualquer tipo de empresa.</h2>

            <br>
            <div class="container mt-5">
                <div class="row d-flex justify-content-center">
                    <!-- <div class="col-12 col-md-6 col-lg-3">
                        <div class="cardPlan">
                            <span class="cardPlanTitle poppins">Startup</span>
                            <br>
                            <div class="d-flex w-100" style="align-items: center; flex-direction: column;">
                                <span class="price">GRATIS</span>
                                <span class="priceSubtitle">1 acesso</span>
                            </div>
                            <br>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> 1 acesso <b>simultâneo</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Suporte <b>via Ticket</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Treinamentos <b>EAD</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Cadastro de <b>1 Cliente</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Cadastro de <b>peças ilimitado</b></span></div>

                            <button [routerLink]="['/assine']" class="assineBtn">ASSINE JÁ</button>
                        </div>
                    </div> -->
                    <div class="col-12 col-md-6 col-lg-3">
                        <!--  -->
                        <div class="cardPlan">
                            <h3 class="cardPlanTitle poppins mb-0">Business</h3>
                            <!-- <br>
                            <div class="d-flex w-100" style="align-items: center; flex-direction: column;">
                                <span class="price">R$ 150,00</span>
                                <span class="priceSubtitle">Por acesso</span>
                            </div> -->
                            <br>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> 1 ou mais <b>acessos simultâneos</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Suporte <b>via Ticket</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Treinamentos <b>EAD</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Clientes <b>ilimitados</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Cadastro de <b>peças ilimitado</b></span></div>

                            <button [routerLink]="['/assine']" class="assineBtn">TESTE JÁ</button>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <!--  -->
                        <div class="cardPlan blueCard">
                            <h3 class="cardPlanTitle poppins black mb-0">Premium</h3>
                            <!-- <br>
                            <div class="d-flex w-100" style="align-items: center; flex-direction: column;">
                                <span class="price">R$ 200,00</span>
                                <span class="priceSubtitle">Por acesso</span>
                            </div> -->
                            <br>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> 1 ou mais <b>acessos simultâneos</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Suporte <b>via Ticket e E-mail</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Treinamentos <b>dedicados</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Clientes <b>ilimitados</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Cadastro de <b>peças ilimitado</b></span></div>

                            <button [routerLink]="['/assine']" class="assineBtn" style="border: 3px solid #fff;">TESTE JÁ</button>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <!--  -->
                        <div class="cardPlan">
                            <h3 class="cardPlanTitle poppins  mb-0">Enterprise</h3>
                            <!-- <br>
                            <div class="d-flex w-100" style="align-items: center; flex-direction: column;">
                                <span class="price">CONSULTE</span>
                                <span class="priceSubtitle">&nbsp;</span>
                            </div> -->
                            <br>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> 1 ou mais <b>acessos simultâneos</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Suporte <b>dedicado por telefone</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Treinamentos <b>dedicados</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> Clientes e peças <b>ilimitados</b></span></div>
                            <div class="cardPlanItem"><ion-icon name="checkmark-sharp"></ion-icon><span> E mais benefícios, <b>compare...</b></span></div>

                            <button [routerLink]="['/assine']" class="assineBtn">TESTE JÁ</button>
                        </div>
                    </div>
                </div>
            </div>

            <h4 [routerLink]="['/compare']" class="compareText poppins mb-3"> Compare cada plano e conheça seus diferencias, clique aqui!</h4>
            <span class="trial poppins mb-3">*Todos os planos com opção TRIAL de 30 dias, sem necessidade de pagamento</span>

            <br>

        </section>

        <!--  -->
        <section class="pBlue">
            <div class="pBluei">
                <div class="container h-100">
                    <div class="row h-100" style="display: flex; align-items: center;">
                        <div class="col-12 col-md-8" style="display: flex; flex-direction: column; justify-content: center;">
                            <h2 class="pBlueTitle">Nós estamos <br> à disposição</h2>
                            <h2 class="pBlueSubTitle mt-4 mb-0">Fale com nossos especialistas em Qualidade, segurança e proteção de dados. Pergunte, tire suas dúvidas e conheça nosso time!</h2>
                        </div>
                        <div class="col-12 col-md-4"  style="display: flex; flex-direction: column; justify-content: center;">
                            <br class="d-flex d-md-none">
                            <br class="d-flex d-md-none">
                            <button (click)="goSection(5)" class="btnPBlue">Fale Conosco</button>
                        </div>
                    </div>
                </div>
                <div #partPerg></div>
            </div>
        </section>

        <!--  -->
        <div style="position: relative;">
            <div id="perguntas" style="position: absolute; margin-top: -50px;"></div>
        </div>
        <section class="pGray">
            <div style="height: 1px;"></div>

            <div class="container">

                <h2 class="p2Title mb-0">Perguntas Frequentes</h2>
                <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                    <div class="blue-line">
                        <span></span>
                    </div>
                </div>
                <h2 class="p2SubTitle container mb-0">Ainda tem dúvidas? Veja algumas das perguntas que sempre fazem pra nós!</h2>

                <div class="w-100 d-block mt-5">

                    <div class="accor {{accordionOpen == 1 ? 'accorOpen' : ''}}" (click)="openAccor(1)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <h3 class="accorTitle">Está disponível o formulário do FMEA - VDA?</h3>
                        </div>
                        <h4 class="AccorRes poppins">
                            Sim! O Q1 está atualizado e o usuário pode escolher entre o formulário do FMEA 4ª Ed e o FMEA - VDA. Na utilização do FMEA-VDA a Prioridade de Ação (PA) é definida automaticamente pelo sistema de acordo com os critérios definido na tabela do manual.
                        </h4>
                    </div>

                    <div class="accor {{accordionOpen == 2 ? 'accorOpen' : ''}}" (click)="openAccor(2)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <h3 class="accorTitle">Como funciona o licenciamento do Quality?</h3>
                        </div>
                        <h4 class="AccorRes poppins">
                            O QualityManager ® é licenciado no formato SaaS, ou Software como Serviço, é um modelo de uso de software baseado na nuvem, ou seja, o sistema fica alojado remotamente e deve ser acessado via Internet.
                        </h4>
                    </div>

                    <div class="accor {{accordionOpen == 3 ? 'accorOpen' : ''}}" (click)="openAccor(3)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <h3 class="accorTitle">Há algum limite de usuário no QualityManager ®?</h3>
                        </div>
                        <h4 class="AccorRes poppins">
                            Não temos limite de usuários e de cadastros, seja de peças, clientes ou fornecedores, você pode cadastrar quantos usuários desejar. O software é baseado em licenças de acesso simultâneo, que não limitam a quantidade de funcionários cadastrados no sistema.
                        </h4>
                    </div>

                    <div class="accor {{accordionOpen == 4 ? 'accorOpen' : ''}}" (click)="openAccor(4)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <h3 class="accorTitle">Qual a infraestrutura de TI que minha empresa precisa para instalar o QualityManager ®?</h3>
                        </div>
                        <h4 class="AccorRes poppins">
                            Por ser um sistema web, o único requisito para que o sistema funcione é uma conexão de internet estável, nada mais.
                        </h4>
                    </div>

                    <!-- <div class="accor {{accordionOpen == 5 ? 'accorOpen' : ''}}" (click)="openAccor(5)">
                        <div style="display: flex; align-items: center; height: 50px;">
                            <ion-icon name="chevron-down-outline"></ion-icon>
                            <span class="accorTitle">A versão STARTUP é realmente gratuita? Consigo emitir um PPAP completo para meu cliente?</span>
                        </div>
                        <span class="AccorRes poppins">
                            SIM. totalmente gratuita e com todas as vantagens de nossa plataforma. Para saber mais sobre as diferenças entre os demais planos acesse: https://br.QualityManager ®/compare/.
                        </span>
                    </div> -->

                    <div class="w-100 w-100 d-flex justify-content-end">
                        <span (click)="faq()" class="compareText poppins mb-3">Ainda tem dúvidas? Veja o FAQ completo </span>
                    </div>

                </div>

            </div>
            <div #partCont></div>


        </section>

        <!--  -->
        <div style="position: relative;">
            <div id="contato" style="position: absolute; margin-top: -50px;"></div>
        </div>
        <section class="pWhite">
            <div style="height: 1px;"></div>

            <h2 class="p2Title mb-0">Entre em contato agora mesmo!</h2>
            <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                <div class="blue-line">
                    <span></span>
                </div>
            </div>

            <div class="text-center mt-4">
                <h4 class="poppins d-flex city mb-0"> <ion-icon class="iconLast" name="location-sharp"></ion-icon> São Paulo – Brasil</h4>
                <h5 class="poppins d-flex normal mb-0">Avenida Brigadeiro Faria Lima, 1811</h5>
                <h5 class="poppins d-flex normal mb-0">Conjunto 1119</h5>
                <h5 class="poppins d-flex normal mb-0">Jardim América</h5>
                <h5 class="poppins d-flex normal mb-0">CEP 01452-001</h5>
                <br>

                <h4 class="poppins d-flex normal mb-0"> <ion-icon class="iconLast" name="call"></ion-icon> Tel.: (+55) 11 2189 0150</h4>
                <h4 class="poppins d-flex normal mb-0"> <ion-icon class="iconLast" name="logo-whatsapp"></ion-icon> WhatsApp: (+55) 11 98560 2580</h4>
                <br>

                <h4 (click)="sendEmail()" class="poppins d-flex normal blueHover  mb-0" style="color: black;"> <ion-icon class="iconLast" name="send"></ion-icon> help&#64;qualitymanager.com.br</h4>

                <div class="d-flex justify-content-center mt-3 mb-3">
                    <div (click)="face()" class="iconDiv"><ion-icon name="logo-facebook"></ion-icon></div>
                    <div (click)="linkedin()" class="iconDiv"><ion-icon name="logo-linkedin"></ion-icon></div>
                    <div (click)="insta()" class="iconDiv"><ion-icon name="logo-instagram"></ion-icon></div>
                    <div (click)="whats()" class="iconDiv"><ion-icon name="logo-whatsapp"></ion-icon></div>
                    <div (click)="twitter()" class="iconDiv"><ion-icon name="logo-twitter"></ion-icon></div>
                </div>

            </div>

        </section>

    </div>

    <app-footer></app-footer>
</div>

<!-- whats btn -->
<div class="float-whats-btn" (click)="whats()">
    <ion-icon name="logo-whatsapp"></ion-icon>
</div>