<div style="background-color: #f1f1f1;">
    <app-header></app-header>
    <div class="headerHeight"></div>

    <div class="content">
        <div class="container mt-4">
            <div class="row">
                <div class="d-none d-xl-flex col-xl-6" style="justify-content: center;">
                    <div class="d-block" style="width: 380px;">
                        <h1 class="title mb-0">Bem vindo ao time<br> QualityManager ®</h1>
                        <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
                            <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="Divisoria de conteudo">
                        </div>
                        <div class="wrapper" style="max-height: 546px;">
                            <img loading="lazy" class="bubble one float-team" src="assets/team/time-1.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <img loading="lazy" class="bubble two float-team" src="assets/team/time-2.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <img loading="lazy" class="bubble three float-team" src="assets/team/time-3.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <div class="bubble-icon nome float-team"></div>
                            <img loading="lazy" class="bubble four float-team" src="assets/team/time-4.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <img loading="lazy" class="bubble five float-team" src="assets/team/time-5.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <img loading="lazy" class="bubble six float-team" src="assets/team/time-6.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <img loading="lazy" class="bubble seven float-team" src="assets/team/time-7.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <img loading="lazy" class="bubble eight float-team" src="assets/team/time-8.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <img loading="lazy" class="bubble nine float-team" src="assets/team/time-9.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <img loading="lazy" class="bubble ten float-team" src="assets/team/time-10.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <img loading="lazy" class="bubble eleven float-team" src="assets/team/quality.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <img loading="lazy" class="bubble twelve float-team" src="assets/team/time-11.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <img loading="lazy" class="bubble thirteen float-team" src="assets/team/time-12.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <img loading="lazy" class="bubble fourteen float-team" src="assets/team/time-1.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <img loading="lazy" class="bubble-icon aws float-team" src="assets/team/aws.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <img loading="lazy" class="bubble-icon chain float-team" src="assets/team/chain.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <img loading="lazy" class="bubble-icon cloud float-team" src="assets/team/cloud.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <img loading="lazy" class="bubble-icon lock float-team" src="assets/team/lock.png" alt="Representação de uma das pessoas do time QualityManager" />
                            <img loading="lazy" class="bubble-icon cloud-red float-team" src="assets/team/cloud-red.png" alt="Representação de uma das pessoas do time QualityManager" />
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-6">

                    <!-- <swiper-container #swiperRef init="false"> -->

                        <!-- Step 1 -->
                        <!-- <swiper-slide> -->
                        <div *ngIf="step == 1" class="cardQ" [formGroup]="form1">

                            <div class="w-100 stepperq">
                                <div class="stepq">
                                    <div class="stepBall stepBallActive">1</div>
                                    <!-- <span class="stepText">Dados da<br> empresa</span> -->
                                </div>
                                <div class="stepq">
                                    <div class="stepBall">2</div>
                                    <!-- <span class="stepText">Dados da<br> empresa</span> -->
                                </div>
                                <div class="stepq">
                                    <div class="stepBall">3</div>
                                    <!-- <span class="stepText">Plano</span> -->
                                </div>
                                <div class="stepq">
                                    <div class="stepBall">4</div>
                                    <!-- <span class="stepText">Tratamento<br> de dados</span> -->
                                </div>
                            </div>
                            <span class="stepTitle">Dados da empresa</span>
                            <br>

                            <input type="text" placeholder="Razão social da empresa*" class="inputq w-100 mt-3" formControlName="razao_social" maxlength="60">
                            <input type="text" placeholder="Website da empresa*" (keyup)="siteChange($event)" class="inputq w-100 mt-3" formControlName="site" maxlength="50">
                            <input type="text" placeholder="Insira o CNPJ da empresa*" class="inputq w-100 mt-3" formControlName="cnpj" mask="00.000.000/0000-00">
                            <div class="d-flex">
                                <input type="text" placeholder="Telefone" class="inputq w-100 mt-3" formControlName="contato2" mask="(00) 00000-0009">
                            </div>
                            <input type="text" placeholder="Nome completo de quem assina pela empresa" class="inputq w-100 mt-3" formControlName="nome_assina" maxlength="100">
                            <input type="text" placeholder="E-mail de quem assina pela empresa" class="inputq w-100 mt-3" formControlName="email_assina" maxlength="100">

                            <br>
                            <span class="stepTitle">Dados do solicitante</span>
                            <br>
                            <input type="text" placeholder="Seu nome completo*" class="inputq w-100 mt-2" formControlName="nome_completo" maxlength="60">
                            <div class="d-flex">
                                <input type="text" placeholder="Celular*" class="inputq w-100 mt-3" style="margin-right: 12px;" formControlName="contato1" mask="(00) 00000-0000">
                                <input type="text" placeholder="Ramal" class="inputq w-100 mt-3" formControlName="ramal" maxlength="4">
                            </div>
                            
                            <!-- <div class="inputq w-100 mt-3 d-flex" style="cursor: text" (click)="inp.focus()">
                                <input #inp type="text" placeholder="Prefixo do e-mail comercial*" class="inputNone" [ngStyle]="{ 'width.ch': getTextSize(form1.controls.email.value) }" formControlName="email">
                                <span *ngIf="form1.controls.email.value != ''" style="margin-top: 3px; user-select: none;">@{{website}}</span>
                            </div> -->

                            <div class="w-100 d-flex">
                                <input type="text" placeholder="Prefixo do e-mail comercial*" class="inputq w-100 mt-3" style="margin-right: 12px;" formControlName="email" maxlength="40">
                                <div class="inputq w-100 mt-3 d-flex" style="background-color: #F0F0F0 !important;">
                                    <span *ngIf="website" style="margin-top: 3px; user-select: none;">&#64;{{website}}</span>
                                </div>
                            </div>

                            <span *ngIf="errorPart1" class="errMessage">{{errorPart1Message}}</span>

                            <div class="w-100 d-flex justify-content-center mt-4">
                                <button class="btnSend" (click)="nextStep()">PROXIMO</button>
                            </div>

                        </div>
                        <!-- </swiper-slide> -->

                        <!-- Step 1.5 -->
                        <!-- <swiper-slide> -->
                            <div *ngIf="step == 1.5" class="cardQ" [formGroup]="form1p2">

                                <div class="w-100 stepperq">
                                    <div class="stepq">
                                        <div class="stepBall">1</div>
                                    </div>
                                    <div class="stepq">
                                        <div class="stepBall stepBallActive">2</div>
                                    </div>
                                    <div class="stepq">
                                        <div class="stepBall">3</div>
                                    </div>
                                    <div class="stepq">
                                        <div class="stepBall">4</div>
                                    </div>
                                </div>
                                <span class="stepTitle">Endereço da empresa</span>
                                <br>

                                <div class="d-flex mt-3 w-100">
                                    <div class="d-flex w-100" style="position: relative;">
                                        <input type="text" placeholder="CEP*" class="inputq w-100" formControlName="cep" mask="00000-000" (keyup)="cepChange()" (change)="cepChange()">
                                        <mat-spinner *ngIf="loadingCEP" class="mat-spinner-color" style="height: 22px; margin-top: 11px; margin-right: 12px; width: 22px; position: absolute; right: 0;"></mat-spinner>
                                    </div>
                                    <a href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank" class="nocep">Não sei meu CEP</a>
                                </div>
                                <div class="d-flex">
                                    <input type="text" placeholder="Endereço*" class="inputq mt-3" style="margin-right: 12px; width: 70%;" formControlName="rua" maxlength="50">
                                    <input type="text" placeholder="Número*" class="inputq mt-3" style="width: 30%;" formControlName="num_endereco">
                                </div>
                                <div class="d-flex">
                                    <input type="text" placeholder="Bairro*" class="inputq w-100 mt-3" style="margin-right: 12px;" formControlName="bairro" maxlength="40">
                                    <input type="text" placeholder="Complemento" class="inputq w-100 mt-3" formControlName="complemento">
                                </div>
                                <div class="d-flex">
                                    <input type="text" placeholder="Cidade*" class="inputq w-100 mt-3" style="margin-right: 12px;" formControlName="cidade" maxlength="40">
                                    <input type="text" placeholder="Estado*" class="inputq w-100 mt-3" formControlName="estado" maxlength="30">
                                </div>

                                <span *ngIf="errorPart1p2" class="errMessage">{{errorPart1p2Message}}</span>

                                <div class="w-100 d-flex justify-content-center mt-4">
                                    <button class="btnSend btnSendOut" style="margin-right: 10px;" (click)="backStep()">VOLTAR</button>
                                    <button class="btnSend" (click)="nextStep()">
                                        <span *ngIf="Part1p2Loading == false">PROXIMO</span>
                                        <mat-spinner *ngIf="Part1p2Loading == true" style="width: 21px; height: 21px; color: white; margin-left: 15px; margin-right: 15px;" class="mat-spinner-color-white"></mat-spinner>
                                    </button>
                                </div>

                            </div>
                        <!-- </swiper-slide> -->

                        <!-- 2 -->
                        <!-- <swiper-slide> -->
                        <div *ngIf="step == 2" class="cardQ" [formGroup]="form2">

                            <div class="w-100 stepperq">
                                <div class="stepq">
                                    <div class="stepBall">1</div>
                                </div>
                                <div class="stepq">
                                    <div class="stepBall ">2</div>
                                </div>
                                <div class="stepq">
                                    <div class="stepBall stepBallActive">3</div>
                                </div>
                                <div class="stepq">
                                    <div class="stepBall">4</div>
                                </div>
                            </div>
                            <span class="stepTitle">Plano</span>
                            <br>

                            <!-- Plano -->
                            <select class="selectq mt-3" formControlName="plan" (change)="changePlan()">
                                <option value="" disabled selected>Selecione o plano desejado*</option>
                                <!-- <option [value]="item.chave" *ngFor="let item of plans">{{item?.nome}} - {{getValue(item)}}</option> -->
                                <option [value]="item.chave" *ngFor="let item of plans">{{item?.nome}}</option>
                            </select>

                            <span class="textImp">*Todos os planos com opção TRIAL de 30 dias, sem necessidade de pagamento</span>

                            <!-- Coisas do free -->
                            <span *ngIf="form2.controls.plan.value == 'startup'" class="text mt-2 d-flex">Estou ciente das Condições de contratação para o plano <b style="margin-left: 4px;"> Startup:</b></span>
                            <div *ngIf="form2.controls.plan.value == 'startup'" class="checkbox">
                                <input type="checkbox" id="1" name="subscribe" value = "newsletter" formControlName="startup_check1">
                                <label for="1" class="text"> Cadastro sujeito a aprovação; </label>
                            </div>
                            <div *ngIf="form2.controls.plan.value == 'startup'" class="checkbox">
                                <input type="checkbox" id="2" name="subscribe" value = "newsletter" formControlName="startup_check2">
                                <label for="2" class="text"> Sou uma empresas de manufatura; </label>
                            </div>
                            <div *ngIf="form2.controls.plan.value == 'startup'" class="checkbox">
                                <input type="checkbox" id="3" name="subscribe" value = "newsletter" formControlName="startup_check3">
                                <label for="3" class="text"> Não sou uma consultoria ou empresa de tecnologia. </label>
                            </div>

                            <!-- qtd access -->
                            <!-- <select *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != 'startup' && form2.controls.plan.value != ''" class="selectq mt-3" formControlName="qtd">
                                <option value="" disabled selected>Selecione a quantidade de acessos*</option>
                                <option [value]="item.quantidade_acessos" *ngFor="let item of getQtdAccess(form2.controls.plan.value)">{{item.quantidade_acessos}} acesso{{item.quantidade_acessos == 1 ? '' : 's'}} - R${{item.valor}}</option>
                            </select> -->

                            <!-- pagar quantos meses -->
                            <!-- <select *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != 'startup' && form2.controls.plan.value != ''" class="selectq mt-3" formControlName="renovacao">
                                <option value="" disabled selected>Período de renovação*</option>
                                <option [value]="item.id" *ngFor="let item of prazos">{{item.nome}} {{item?.desconto > 0 ? ' - ' + item?.desconto + '% de desconto' : ''}}</option>
                            </select> -->

                            <!-- implementação -->
                            <select *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != 'startup' && form2.controls.plan.value != ''" class="selectq mt-3" formControlName="implementacao">
                                <option value="0" disabled selected>Tem interesse em consultoria para implementação?*</option>
                                <!-- <option value="1">Sim, desejo auxílio para implementação - R${{valorImplementacao.toFixed(2)}}</option> -->
                                <option value="1">Sim, desejo auxílio para implementação</option>
                                <option value="2">Não, vou implementar o Quality sozinho</option>
                            </select>
                            <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != 'startup' && form2.controls.plan.value != ''" class="textImp">*Consultoria para implementação – Se contratado, um de nossos gestores irá lhe ajudar no onboarding de todo o processo de implementação do software na sua empresa durante os primeiros 30 dias após a contratação. Este é um serviço opcional, pois acreditamos que nossa solução é de fácil implementação para grande maioria dos usuários que possuem familiaridade com sistemas web.</span>

                            <!-- <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text mt-3 d-flex" style="font-size: 16px;"><b> Resumo:</b></span>
                            <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text d-flex lh"><b> Licenças de utilização QualityManager ®</b></span>
                            <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text d-flex lh"><b> Plano:</b>&nbsp;{{form2.controls.plan.value}}</span>
                            <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text d-flex lh"><b> Empresa:</b>&nbsp;{{form1.controls.razao_social.value}}</span>
                            <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text d-flex lh"><b> Quantidade de Acessos:</b>&nbsp;{{form2.controls.qtd.value != '' ? form2.controls.qtd.value : '1'}}</span>
                            <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != 'startup' && form2.controls.plan.value != '' && form2.controls.implementacao.value != 0" class="text lh"><b> Consultoria para implementação:</b>&nbsp;{{form2.controls.implementacao.value == 1 ? 'Sim - R$' + valorImplementacao.toFixed(2) + ' (Pagamento único)' : 'Não - grátis'}}</span>
                            <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text d-flex lh"><b> Valor total:</b>&nbsp;<span *ngIf="getTotalValue(false) != getTotalValue()" style="text-decoration: line-through; color: #df4a4a;">R${{getTotalValue(false)}}</span> &nbsp; <span>R${{getTotalValue()}}</span></span>
                            <br>
                            <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != 'startup' && form2.controls.plan.value != ''" style="font-style: italic;" class="text d-flex lh">*Todos os impostos já estão inclusos</span>
                            <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != 'startup' && form2.controls.plan.value != ''" style="font-style: italic;" class="text d-flex lh">*A Nota Fiscal será enviada após a confirmação do pagamento</span> -->

                            <span *ngIf="errorPart2" class="errMessage">{{errorPart2Message}}</span>

                            <div class="w-100 d-flex justify-content-center mt-4">
                                <button class="btnSend btnSendOut" style="margin-right: 10px;" (click)="backStep()">VOLTAR</button>
                                <button class="btnSend" (click)="nextStep()">PROXIMO</button>
                            </div>

                        </div>
                        <!-- </swiper-slide> -->

                        <!-- 3 -->
                        <!-- <swiper-slide> -->
                        <div *ngIf="step == 3" class="cardQ" [formGroup]="form3">

                            <div class="w-100 stepperq">
                                <div class="stepq">
                                    <div class="stepBall">1</div>
                                </div>
                                <div class="stepq">
                                    <div class="stepBall ">2</div>
                                </div>
                                <div class="stepq">
                                    <div class="stepBall">3</div>
                                </div>
                                <div class="stepq">
                                    <div class="stepBall stepBallActive">4</div>
                                </div>
                            </div>
                            <span class="stepTitle">Tratamento de dados</span>
                            <br>

                            <span class="text mt-2 d-flex">Autorização e tratamento de dados:</span>
                            <div class="checkbox">
                                <input type="checkbox" id="4" name="subscribe" value = "newsletter" formControlName="check1">
                                <label class="text" for="4"> Autorizo o tratamento e comunicação dos dados para efeitos de compliance para LGPD. </label>
                            </div>
                            <div class="checkbox">
                                <input type="checkbox" id="5" name="subscribe" value = "newsletter" formControlName="check2">
                                <label class="text" style="display: block;" for="5"> Li e concordo com os termos e condições e política de privacidade do software - <a href="https://qualitymanager.com.br/termos-e-condicoes" target="_blank">https://qualitymanager.com.br/termos-e-condicoes</a></label>
                            </div>

                            <span *ngIf="errorPart3" class="errMessage">{{errorPart3Message}}</span>

                            <div class="w-100 d-flex justify-content-center mt-4">
                                <button class="btnSend btnSendOut" style="margin-right: 10px;" (click)="backStep()">VOLTAR</button>
                                <button class="btnSend" (click)="nextStep()">PROXIMO</button>
                            </div>

                        </div>
                        <!-- </swiper-slide> -->

                        <!-- 4 - pagamento -->
                        <!-- <swiper-slide> -->
                        <div *ngIf="step == 4" class="cardQ" [formGroup]="form4">
                            <span class="titlePag d-flex">Selecione o método de pagamento:</span>

                            <div class="d-flex">
                                <div (click)="selectedPayOption = 1" class="payOption {{selectedPayOption == 1 ? 'payOptionActive' : ''}}" style="margin-right: 11px;">
                                    <ion-icon class="iconCard" name="card"></ion-icon>
                                    Cartão de Crédito
                                </div>
                                <div (click)="selectedPayOption = 2; genPix()" class="payOption {{selectedPayOption == 2 ? 'payOptionActive' : ''}}">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="iconPix" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 194 194" style="enable-background:new 0 0 194 194;" xml:space="preserve">
                                        <g>
                                        <path id="path2376_2_" d="M147.04,144.34c-7.02,0-13.6-2.7-18.57-7.67L101.7,109.9c-1.84-1.84-5.18-1.84-7.02,0L67.8,136.78   c-4.97,4.97-11.55,7.67-18.57,7.67h-5.29l34.01,34.01c10.58,10.58,27.85,10.58,38.43,0l34.11-34.11L147.04,144.34L147.04,144.34z"/>
                                        <path id="path2380_2_" d="M49.12,49.55c7.02,0,13.6,2.7,18.57,7.67L94.57,84.1c1.94,1.94,5.07,1.94,7.02,0l26.88-26.77   c4.97-4.97,11.55-7.67,18.57-7.67h3.24l-34.11-34.11c-10.58-10.58-27.85-10.58-38.43,0L43.72,49.55H49.12L49.12,49.55z"/>
                                        <path id="path2384_2_" d="M178.45,77.84l-20.62-20.62c-0.43,0.22-0.97,0.32-1.51,0.32h-9.39c-4.86,0-9.61,1.94-12.95,5.4   L107.2,89.71c-2.48,2.48-5.83,3.78-9.07,3.78c-3.35,0-6.59-1.3-9.07-3.78L62.18,62.83c-3.45-3.45-8.2-5.4-12.95-5.4H37.68   c-0.54,0-0.97-0.11-1.4-0.32L15.55,77.84c-10.58,10.58-10.58,27.85,0,38.43l20.62,20.62c0.43-0.22,0.86-0.32,1.4-0.32h11.55   c4.86,0,9.61-1.94,12.95-5.4l26.88-26.88c4.86-4.86,13.39-4.86,18.24,0l26.77,26.77c3.45,3.45,8.2,5.4,12.95,5.4h9.39   c0.54,0,0.97,0.11,1.51,0.32l20.62-20.62C189.03,105.58,189.03,88.42,178.45,77.84"/>
                                        </g>
                                    </svg>
                                    PIX
                                </div>
                            </div>

                            <!-- cartão -->
                            <div *ngIf="selectedPayOption == 1" class="">
                                <div class="w-100 mt-3" style="position: relative;">
                                    <input type="text" placeholder="Número do cartão*" class="inputq w-100" formControlName="card_number" mask="0000 0000 0000 000099"  maxlength="21">
                                    <app-band-card style="position: absolute; right: 0; top: 0; margin-top: 7px; margin-right: 6px;" [bandeira]="getCreditCardLabel()"></app-band-card>
                                </div>
                                <input type="text" placeholder="Nome impresso no cartão*" class="inputq w-100 mt-3" formControlName="card_name" maxlength="64">
                                <div class="d-flex">
                                    <input type="text" placeholder="Validade*" class="inputq w-100 mt-3" style="margin-right: 12px;" formControlName="card_month_year" mask="00/00">
                                    <input type="text" placeholder="CVV*" class="inputq w-100 mt-3" formControlName="card_cvv" maxlength="4">
                                </div>
                                <!-- <select class="selectq mt-3" formControlName="pay_type">
                                    <option value="" disabled selected>Tipo de pagamento*</option>
                                    <option value="1">Pagamento único</option>
                                    <option value="2">Pagamento recorrente</option>
                                </select> -->
                                
                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text mt-3 d-flex" style="font-size: 16px;"><b> Resumo:</b></span>
                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text d-flex lh"><b> Licenças de utilização QualityManager ®</b></span>
                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text d-flex lh"><b> Plano:</b>&nbsp;{{form2.controls.plan.value}}</span>
                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text d-flex lh"><b> Empresa:</b>&nbsp;{{form1.controls.razao_social.value}}</span>
                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text d-flex lh"><b> Quantidade de Acessos:</b>&nbsp;{{form2.controls.qtd.value != '' ? form2.controls.qtd.value : '1'}}</span>
                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != 'startup' && form2.controls.plan.value != '' && form2.controls.implementacao.value != 0" class="text lh"><b> Consultoria para implementação:</b>&nbsp;{{form2.controls.implementacao.value == 1 ? 'Sim - R$' + valorImplementacao.toFixed(2) + ' (Pagamento único)' : 'Não - grátis'}}</span>
                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text d-flex lh"><b> Valor total:</b>&nbsp;R${{getTotalValue()}}</span>
                                <br>
                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != 'startup' && form2.controls.plan.value != ''" style="font-style: italic;" class="text d-flex lh">*Todos os impostos já estão inclusos</span>
                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != 'startup' && form2.controls.plan.value != ''" style="font-style: italic;" class="text d-flex lh">*A Nota Fiscal será enviada após a confirmação do pagamento</span>

                                <span *ngIf="errorPart4" class="errMessage">{{errorPart4Message}}</span>

                                <div class="w-100 d-flex justify-content-center mt-4" >
                                    <button class="btnSend" (click)="finishPaymentCard()">
                                        <span *ngIf="loadingCard == false">Finalizar pagamento</span>
                                        <div *ngIf="loadingCard == true" style="display: flex; justify-content: center; width: 136px;">
                                            <mat-spinner style="width: 21px; height: 21px; color: white;" class="mat-spinner-color-white"></mat-spinner>
                                        </div>
                                    </button>
                                </div>

                            </div>

                            <!-- PIX -->
                            <div *ngIf="selectedPayOption == 2" class="">

                                <div *ngIf="!pixData?.payload" class="w-100 d-flex justify-content-center mt-5">
                                    <mat-spinner class="mat-spinner-color" style="height: 45px; width: 45px;"></mat-spinner>
                                </div>
                                <br *ngIf="!pixData?.payload">

                                <div *ngIf="pixData?.payload" class="w-100">
                                    <div class="w-100 d-flex justify-content-center">
                                        <div class="mt-4" style="width: 200px; border-radius: 7px; height: 200px; background-color: #dddddd; display: flex; justify-content: center; align-items: center; padding: 3px;">
                                            <img loading="lazy" [src]="getPixImage()" style="width: 100%; background-color: white; border-radius: 7px;" alt="QrCode do PIX">
                                        </div>
                                    </div>
                                    <div style="height: 10px;"></div>
                                    <span style="display: block; justify-content: center; text-align: center; line-break: anywhere;">
                                        <span style=" text-align: center; font-family: Poppins; color: darkslategray; line-height: 24px;">
                                            {{pixData?.payload}}
                                        </span>
                                        <ion-icon style="font-size: 18px; margin-bottom: -4px; margin-left: 2px; cursor: pointer;" name="clipboard-outline" (click)="copy(pixData?.payload)"></ion-icon>
                                    </span>

                                    <span style="width: 100%; justify-content: center; align-items: center; display: flex; margin-top: 18px;">
                                        <mat-spinner style="height: 25px; width: 25px;"></mat-spinner>
                                        <span style="margin-left: 10px; font-family: Poppins; font-size: 16px;">Aguardando pagamento</span>
                                    </span>
                                    <span class="pixValid">Esse código é válido pelos próximos 60 minutos</span>
                                </div>

                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text mt-3 d-flex" style="font-size: 16px;"><b> Resumo:</b></span>
                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text d-flex lh"><b> Licenças de utilização QualityManager ®</b></span>
                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text d-flex lh"><b> Plano:</b>&nbsp;{{form2.controls.plan.value}}</span>
                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text d-flex lh"><b> Empresa:</b>&nbsp;{{form1.controls.razao_social.value}}</span>
                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text d-flex lh"><b> Quantidade de Acessos:</b>&nbsp;{{form2.controls.qtd.value != '' ? form2.controls.qtd.value : '1'}}</span>
                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != 'startup' && form2.controls.plan.value != '' && form2.controls.implementacao.value != 0" class="text lh"><b> Consultoria para implementação:</b>&nbsp;{{form2.controls.implementacao.value == 1 ? 'Sim - R$' + valorImplementacao.toFixed(2) + ' (Pagamento único)' : 'Não - grátis'}}</span>
                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != ''" class="text d-flex lh"><b> Valor total:</b>&nbsp;R${{getTotalValue()}}</span>
                                <br>
                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != 'startup' && form2.controls.plan.value != ''" style="font-style: italic;" class="text d-flex lh">*Todos os impostos já estão inclusos</span>
                                <span *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != 'startup' && form2.controls.plan.value != ''" style="font-style: italic;" class="text d-flex lh">*A Nota Fiscal será enviada após a confirmação do pagamento</span>
                                
                            </div>

                        </div>
                        <!-- </swiper-slide> -->

                        <!-- 10 - Finalizado -->
                        <!-- <swiper-slide> -->
                        <div *ngIf="step == 10" class="cardQ" style="height: 522px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                            
                            <div class="d-flex justify-content-center">
                                <ion-icon style="font-size: 76px; color: #18c3f8;" name="checkmark-circle-outline"></ion-icon>
                            </div>
                            <!-- <div *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != 'startup'" class="d-flex justify-content-center" style="margin-bottom: 17px; flex-direction: column; align-items: center;">
                                <span class="finishText">Seu pagamento foi processado com sucesso, obrigado!</span>
                                <span class="finishText">O contrato e o documento fiscal serão enviados por e-mail assim que o cadastro for ativado por nossa equipe.</span>
                                <span class="finishText">Seja bem vindo ao QualityManager ®!</span>
                            </div> -->
                            <div *ngIf="form2.controls.plan.value != 'enterprise' && form2.controls.plan.value != 'startup'" class="d-flex justify-content-center" style="margin-bottom: 17px; flex-direction: column; align-items: center;">
                                <span class="finishText">Seu pedido foi realizado com sucesso, obrigado!</span>
                                <span class="finishText">Nosso time realizará a avaliação para liberação de seus dados de acesso.</span>
                                <span class="finishText">Seja bem vindo ao QualityManager ®!</span>
                            </div>
                            <div *ngIf="form2.controls.plan.value == 'enterprise'" class="d-flex justify-content-center" style="margin-bottom: 17px; flex-direction: column; align-items: center;">
                                <span class="finishText">Seu contato foi realizado com sucesso, obrigado!</span>
                                <span class="finishText">Um de nossos consultores entrará em contato para apresentar todos os benefícios e vantagens que o Quality pode te oferecer!</span>
                            </div>
                            <div *ngIf="form2.controls.plan.value == 'startup'" class="d-flex justify-content-center" style="margin-bottom: 17px; flex-direction: column; align-items: center;">
                                <span class="finishText">Seu pedido foi realizado com sucesso, obrigado!</span>
                                <span class="finishText">Nosso time realizará a avaliação para liberação de seus dados de acesso.</span>
                                <span class="finishText">Seja bem vindo ao QualityManager ®!</span>
                            </div>

                        </div>
                        <!-- </swiper-slide> -->
                    
                    <!-- </swiper-container> -->
                </div>
            </div>
        </div>

        <br><br>
    </div>

    <app-footer></app-footer>
</div>