<div class="page">
    <app-header></app-header>
    <div class="headerHeight"></div>

    <!-- part 1 -->
    <div class="content">
        <div style="height: 1px;"></div>
        <span class="not">404 - Not Found</span>
    </div>

    <app-footer></app-footer>
</div>