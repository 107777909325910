import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-team-person',
  templateUrl: './team-person.component.html',
  styleUrls: ['./team-person.component.scss']
})
export class TeamPersonComponent {

  @Input() nome: string = '';
  @Input() cargo: string = '';
  @Input() foto: string = '';
  @Input() email: string = '';
  @Input() linkedin: string = '';

}
