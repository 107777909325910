import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss']
})
export class SitemapComponent {

  constructor(public event: EventService, public router: Router) {
    // scroll up
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  goPage(page: number) {
    if (page >= 1 && page <=6) {
      let route = this.router.url;
      this.router.navigate(['/home']);
      this.event.pageChange.emit(page);

      if (route != '/home') {
        setTimeout(()=>{
          this.event.pageChange.emit(page);
        }, 100)
      }
    } else {

    }
  }

}
