<div class="page">
    <app-header></app-header>
    <div class="headerHeight"></div>

    <!-- part 1 -->
    <div class="content">
        <div style="height: 1px;"></div>

    </div>

    <app-footer></app-footer>
</div>