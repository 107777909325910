import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-testeja',
  templateUrl: './testeja.component.html',
  styleUrl: './testeja.component.scss'
})
export class TestejaComponent {

  form = new FormGroup({
    nome: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    telefone: new FormControl('', Validators.required),
    empresa: new FormControl('', Validators.required),
    termos: new FormControl(false),
    termosB: new FormControl(false)
  });

  public error: string = '';

  private env = environment.baseURL;

  constructor(
    public http: HttpClient
  ) { }

  sendForm() {

    // email validation
    const email: any = this.form.controls['email'].value;
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (!emailRegex.test(email)) {
      this.error = 'E-mail inválido.';
      setTimeout(() => {
        this.error = '';
      }, 5000);
      return;
    }

    if (this.form.invalid) {
      this.error = 'Preencha todos os campos corretamente.';
      setTimeout(() => {
        this.error = '';
      }, 5000);
      return;
    }

    if (!this.form.controls['termos'].value) {
      this.error = 'Aceite os termos de uso.';
      setTimeout(() => {
        this.error = '';
      }, 5000);
      return
    }

    if (!this.form.controls['termosB'].value) {
      this.error = 'Aceite os termos de uso.';
      setTimeout(() => {
        this.error = '';
      }, 5000);
      return
    }

    const body: any = {
      nome: this.form.controls['nome'].value,
      email: this.form.controls['email'].value,
      telefone: this.form.controls['telefone'].value,
      empresa: this.form.controls['empresa'].value
    }

    this.http.post<any>(this.env + '/api/contato', body).subscribe({
      next: data => {
        Swal.fire({
          title: 'Sucesso!',
          text: 'Seu contato foi enviado com sucesso, em breve entraremos em contato.',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
        this.form.reset();
      },
      error: error => {
        Swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao enviar seu contato, tente novamente mais tarde.',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    })
  }

}
