<div class="page">
    <app-header></app-header>
    <div class="headerHeight"></div>

    <!-- part 1 -->
    <div class="content">
        <div style="height: 1px;"></div>

        <div class="container xs-10">
            <div class="lgpd-section-title">
                <h2 class="">Contrato de licença de uso e prestação de serviços do QualityManager ® - Rev. 4.0</h2>
            </div>
            <div class="row d-flex flex-center">

                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div>
                        <p class="text-justify">Estes são os termos do contrato de licença de uso e prestação de
                            serviços que regulam o uso do sistema online QualityManager®, também denominado
                            QualityManager ®.<br>
                            Ao utilizar o QualityManager ® você concorda integralmente com esses termos, caso esteja
                            utilizando para uma empresa, você declara que a empresa também está de acordo com esses
                            termos. <br>
                            Se não estiver totalmente de acordo com os termos, não utilize o QualityManager ®.</p>
                        <p class="text-justify"><strong>Licenciante: </strong>Cyber1 do Brasil Ltda, fundada no ano
                            2000, sediada à Rua Nossa Senhora da Lapa, 671 – Cj 11, São Paulo/SP, CEP: 05072-900,
                            inscrita no CNPJ sob o nº. 04.019.962/00001-43 e isenta de inscrição estadual, doravante
                            denominada Licenciante.</p>
                        <p class="text-justify"><strong>Licenciada: </strong>Pessoa Jurídica adquirente desta licença de
                            uso do QualityManager ®, identificada através da aceitação dos termos abaixo e com acordo
                            firmado pelo departamento responsável da Licenciante, doravante denominada Licenciada.</p>

                        <p class="text-justify">As partes acima identificadas têm, entre si, justo e acertado o presente
                            Contrato de Licença de Uso não exclusivo de Prestação de Serviços do Software online
                            QualityManager®, que se regerá pelas cláusulas seguintes e pelas condições descritas no
                            presente.</p>
                        <hr>

                        <p class="text-justify"><strong>Do objeto: </strong></p>
                        <p class="text-justify"><strong>Cláusula 1ª – </strong>O software para gestão da qualidade
                            QualityManager®, também denominado de QualityManager ®, é um software online desenvolvido e de
                            propriedade da Licenciante com o objetivo de gerenciar, elaborar e armazenar todos os dados
                            requeridos durante o Planejamento Avançado da Qualidade do Produto (APQP).</p>
                        <p class="text-justify"><strong>Cláusula 2ª – </strong>QualityManager® é marca registrada no
                            INPI, Instituto Nacional da Propriedade Industrial, sob o nº 901307823.</p>
                        <p class="text-justify"><strong>Cláusula 3ª – </strong>O QualityManager ® é disponibilizado pela
                            Licenciante para utilização da Licenciada via internet através de uma conexão segura de
                            site.</p>
                        <p class="text-justify"><strong>Cláusula 4ª – </strong>O QualityManager ® pode ser tratado também
                            pela denominação de solução web, programa de computador, programa, software, sistema ou
                            site, conforme definição do objeto definido na cláusula 1ª.</p>

                        <p class="text-justify"><strong>Da licença de uso: </strong></p>
                        <p class="text-justify"><strong>Cláusula 5ª – </strong>A presente licença de uso do QualityManager ®
                            terá os aspectos da irretratabilidade e da irrevogabilidade.</p>
                        <p class="text-justify"><strong>Cláusula 6ª – </strong>Este termo do QualityManager ® representa a
                            prova de Licença de Uso e deve ser tratado como propriedade valiosa.</p>
                        <p class="text-justify"><strong>Cláusula 7ª – </strong>A Licenciante, proprietária de todos os
                            direitos do QualityManager ® objeto deste contrato, neste ato e pela melhor forma de direito,
                            outorga a Licenciada a Licença de Uso, em caráter não exclusivo e não transferível do
                            software, objeto desta Licença de Uso, disponibilizado para que o utilize via internet,
                            respeitando os termos constantes desta Licença de Uso.</p>
                        <p class="text-justify"><strong>Cláusula 8ª – </strong>A aceitação deste termo se concretiza na
                            ocasião da utilização, mesmo que parcial, a título de teste ou na aquisição desta Licença de
                            Uso do QualityManager ®, feita eletronicamente através de formulário no site, através da ação
                            específica de envio do formulário e aceite dos termos especificados no formulário.</p>
                        <p class="text-justify"><strong>Cláusula 9ª – </strong>A utilização do QualityManager ®, mesmo que
                            parcial ou a título de teste, indica que a Licenciada está ciente dos termos da Licença de
                            Uso do QualityManager ®, concordando com todos eles.</p>
                        <p class="text-justify"><strong>Cláusula 10ª – </strong>Em caso de discordância dos termos aqui
                            apresentados, a utilização do QualityManager ® deve ser imediatamente interrompida.</p>

                        <p class="text-justify"><strong>Das atualizações: </strong></p>
                        <p class="text-justify"><strong>Cláusula 11ª – </strong>A Licenciante garante, sem custos
                            adicionais a Licenciada, a atualização do sistema sempre que houver modificações nas
                            exigências da norma IATF 16949:2016 e dos manuais de referência (MSA, CEP, FMEA, APQP e
                            PPAP).</p>

                        <p class="text-justify"><strong>Das obrigações da licenciante: </strong></p>
                        <p class="text-justify"><strong>Cláusula 12ª –</strong> Backup semanal da base de dados MySQL
                            com uma retenção mensal.</p>
                        <p class="text-justify"><strong>Cláusula 13ª –</strong> Conexão segura entre o usuário e a
                            aplicação QualityManager ®.</p>
                        <p class="text-justify"><strong>Cláusula 14ª –</strong> Para que a Licenciada possa utilizar o
                            QualityManager ® se faz necessário que ele esteja devidamente instalado e configurado em um
                            servidor de internet pela Licenciante.</p>
                        <p class="text-justify"><strong>Cláusula 15ª –</strong> Manter um SLA de 99,1% do serviço
                            on-line por ano.</p>

                        <p class="text-justify"><strong>Das obrigações da licenciada: </strong></p>
                        <p class="text-justify"><strong>Cláusula 16ª –</strong> A Licenciada é integralmente responsável
                            pelas informações inseridas no sistema, pelo cadastramento, permissões, senhas e modo de
                            utilização de seus usuários.</p>
                        <p class="text-justify"><strong>Cláusula 17ª –</strong> A Licenciada assume a responsabilidade
                            de contratar colaboradores com conhecimentos técnicos da norma IATF 16949:2016 e dos manuais
                            de referência (MSA, CEP, FMEA, APQP e PPAP).</p>
                        <p class="text-justify"><strong>Cláusula 18ª –</strong> A Licenciada deve manter seus
                            colaboradores sempre atualizados sobre as ferramentas da qualidade para que eles possam
                            utilizar a solução QualityManager ® com mais eficiência e qualidade.</p>
                        <p class="text-justify"><strong>Cláusula 19ª –</strong> A responsabilidade pelas informações de
                            cada usuário no QualityManager ® é sempre da Licenciada.</p>
                        <p class="text-justify"><strong>Cláusula 20ª –</strong> A Licenciada deve pagar a Licenciante um
                            valor referente à licença de uso do QualityManager ®.</p>

                        <p class="text-justify"><strong>Dos tipos de licenças: </strong></p>
                        <p class="text-justify"><strong>Cláusula 21ª – </strong>O QualityManager ® disponibiliza quatro
                            tipos de licenças, são elas: Startup, Business, Premium e Enterprise, sendo que as
                            características de cada uma estão disponíveis no seguinte link: <a
                                href="https://qualitymanager.com.br/compare/" target="_blank" style="font-size:14px">
                                https://qualitymanager.com.br/compare/</a></p>

                        <p class="text-justify"><strong>Das condições comerciais: </strong></p>
                        <p class="text-justify"><strong>Cláusula 22ª – </strong>Para a licença Startup não é cobrado
                            nenhum valor para utilização do QualityManager ®, essa versão é totalmente gratuita.</p>
                        <p class="text-justify"><strong>Cláusula 23ª – </strong>O valor para acesso on-line do
                            QualityManager ® será definido de acordo com a quantidade de licenças de simultaneidade de
                            acesso adquiridas e o plano escolhido.</p>
                        <p class="text-justify"><strong>Cláusula 24ª – </strong>Os valores referentes a serviços de
                            homologação, instalações, configurações, manutenções, solicitações de melhorias e outros
                            solicitados futuramente pela Licenciada serão previamente orçados e comunicados a Licenciada
                            através de nova proposta comercial. Novos serviços somente serão executados mediante a
                            aprovação de suas respectivas propostas.</p>
                        <p class="text-justify"><strong>Cláusula 25ª – </strong>Em caso de não pagamento dos valores
                            referentes ao objeto deste contrato, fica a Licenciada sujeita a interrupção temporária ou o
                            cancelamento definitivo dos serviços prestados pela Licenciante.</p>

                        <p class="text-justify"><strong>Das regras para publicação de conteúdo: </strong></p>
                        <p class="text-justify"><strong>Cláusula 26ª – </strong>As seguintes regras devem ser obedecidas
                            pela Licenciada, visando respeito moral e social, bem como o bom e perfeito funcionamento do
                            sistema QualityManager ® (software ou site) sendo expressamente proibida a inclusão dos
                            seguintes conteúdos:<br>
                            <i class="fa fa-check" style="padding-left: 5%;"></i>Publicação ou veiculação de material
                            pornográfico, racista ou ofensivo; <br>
                            <i class="fa fa-check" style="padding-left: 5%;"></i>Incitação ao crime ou informações sobre
                            atividades ilegais; <br>
                            <i class="fa fa-check" style="padding-left: 5%;"></i>Material pornográfico ou atividades
                            ilegais, incluindo menores de 18 (dezoito) anos; <br>
                            <i class="fa fa-check" style="padding-left: 5%;"></i>Material calunioso, que atribua
                            falsamente a alguém fato definido como crime, afirmações injuriosas que ofendam a dignidade
                            ou decoro de alguém bem como afirmações difamatórias, imputando a alguém fato ofensivo à sua
                            reputação; <br>
                            <i class="fa fa-check" style="padding-left: 5%;"></i>Informações relativas à pirataria de
                            software; <br>
                            <i class="fa fa-check" style="padding-left: 5%;"></i>Material protegido por direitos
                            autorais, sendo vetada a publicação de fotos, textos ou arquivos de som sem a autorização do
                            representante da obra ou empresa responsável. <br>
                        </p>
                        <p class="text-justify" style="padding-left: 20%;"><strong>Parágrafo único – </strong>A
                            Licenciante ou seus Representantes, em hipótese alguma serão responsáveis pelo conteúdo
                            (informações, senhas, cópias de informações etc.) de qualquer natureza publicada no
                            software, não sendo, portanto, estas informações revisadas nem divulgadas em momento algum.
                        </p>

                        <p class="text-justify"><strong>Cláusula 27ª – </strong>É expressamente proibido:<br>
                            <i class="fa fa-check" style="padding-left: 5%;"></i>A utilização do QualityManager ® fora das
                            condições estabelecidas nesta Licença de Uso; <br>
                            <i class="fa fa-check" style="padding-left: 5%;"></i>Traduzir, fazer engenharia reversa,
                            decompilar, copiar imagens, códigos ou quaisquer partes do QualityManager ® para utilização fora
                            dele; <br>
                            <i class="fa fa-check" style="padding-left: 5%;"></i>Alugar, arrendar, atribuir ou
                            transferir o produto licenciado; <br>
                            <i class="fa fa-check" style="padding-left: 5%;"></i>Modificar o produto ou mesclar todas ou
                            qualquer de suas partes com outro programa; <br>
                            <i class="fa fa-check" style="padding-left: 5%;"></i>Remover ou alterar qualquer aviso de
                            copyright, marca registrada, ou outro aviso de direitos de propriedade colocados no
                            QualityManager ® ou em parte do mesmo. <br>
                        </p>
                        <p class="text-justify"><strong>Do suporte técnico: </strong></p>
                        <p class="text-justify"><strong>Cláusula 28ª – </strong>As condições de atendimento do suporte
                            técnico serão conforme o plano contratado na aquisição dos serviços.</p>
                        <p class="text-justify"><strong>Cláusula 29ª – </strong>O Suporte técnico poderá ser via e-mail,
                            via sistema de tickets e por telefone, podendo variar de acordo com o plano contratado na
                            aquisição dos serviços.</p>
                        <p class="text-justify"><strong>Cláusula 30ª – </strong>O Suporte técnico é realizado apenas e
                            em horário comercial para utilização dos recursos constantes no QualityManager ®.</p>
                        <p class="text-justify"><strong>Cláusula 31ª – </strong>Considerar horário comercial o intervalo
                            entre as 9h às 12h e entre as 14h às 17h, de segunda a sexta-feira, excluindo os feriados do
                            estado e do município de São Paulo e os nacionais.</p>
                        <p class="text-justify"><strong>Cláusula 32ª – </strong>O tempo de resposta para chamados
                            técnicos, realizados a partir do sistema de tickets, é contabilizado em horário comercial e
                            seu tempo de atendimento poderá variar de acordo com o plano contratado na aquisição dos
                            serviços.</p>
                        <p class="text-justify"><strong>Cláusula 33ª – </strong>O Suporte Técnico fornecido a Licenciada
                            limita-se à esclarecimentos sobre o funcionamento do QualityManager ® e sua operação, assim
                            sendo, pressupõe-se o mínimo de conhecimento do uso do computador e das ferramentas da
                            qualidade por parte do(s) usuário(s), o que inclui o uso do computador e suas funções, o uso
                            do sistema operacional sob o qual o sistema irá trabalhar, e do assunto que o QualityManager ®
                            se propõe a resolver. Os canais de suporte e tempo de resposta podem variar de acordo com o
                            plano contratado.</p>
                        <p class="text-justify"><strong>Cláusula 34ª – </strong>Pressupõe-se também uma configuração
                            adequada do computador no que se refere a utilização do software adquirido e o bom estado de
                            funcionamento deste computador e que ele não tenha mais de 3 (três) anos de uso.</p>
                        <p class="text-justify"><strong>Cláusula 35ª – </strong>O QualityManager ® está homologado
                            <strong>apenas</strong> para ser utilizado com o navegador de internet (Browser) GOOGLE
                            CHROME.</p>
                        <p class="text-justify"><strong>Cláusula 36ª – </strong>O Suporte técnico fornecido pela
                            Licenciante não abrange a conexão com a internet, rede interna e computadores da Licenciada.
                        </p>

                        <p class="text-justify"><strong>Das customizações: </strong></p>
                        <p class="text-justify"><strong>Cláusula 37ª – </strong>Caso a Licenciada necessite de
                            modificações no QualityManager ® para que este atenda melhor suas necessidades o procedimento a
                            ser seguido está descrito abaixo: <br>
                            <i class="fa fa-check" style="padding-left: 5%;"></i>A Licenciada entra em contato com a
                            Licenciante e informa expressamente o que deseja;<br>
                            <i class="fa fa-check" style="padding-left: 5%;"></i>A Licenciante analisa o pedido da
                            Licenciada quanto a sua viabilidade técnica e envia proposta comercial com o valor e o prazo
                            de conclusão dos serviços solicitados;<br>
                            <i class="fa fa-check" style="padding-left: 5%;"></i>Após a aprovação da proposta pela
                            Licenciada, a Licenciante inicia os serviços e disponibiliza as modificações em um ambiente
                            de homologação - outro ambiente com uma cópia do QualityManager ® em funcionamento - para que a
                            Licenciada realize os testes e valide as novas implementações, e assim elas sejam
                            incorporadas ao sistema em sua versão de produção.<br>
                        </p>

                        <p class="text-justify"><strong>Cláusula 38ª –</strong> Todas as modificações, melhorias e
                            correções efetuadas no QualityManager ®, mesmo que informadas, solicitadas, e eventualmente
                            pagas pela Licenciada, ficam incorporadas ao QualityManager ® e sujeitas aos termos desta
                            Licença de Uso do QualityManager ®, podendo inclusive serem disponibilizadas pela Licenciante a
                            outras Licenciadas que utilizem o mesmo modelo de sistema, assim como a Licenciada pode
                            eventualmente receber melhorias de funcionamento, correções e novos recursos no software que
                            utiliza.</p>
                        <p class="text-justify"><strong>Cláusula 39ª –</strong> A Licenciada desde já concorda com
                            modificações, implementação de novos recursos, ou ferramentas, ou melhorias ou correções no
                            modelo de QualityManager ® que utiliza, ficando a critério da Licenciante o gerenciamento e
                            aprovação destas modificações no QualityManager ®.</p>
                        <p class="text-justify"><strong>Cláusula 40ª –</strong> A instalação das atualizações é feita
                            pela Licenciante de forma automática no QualityManager ®.</p>


                        <p class="text-justify"><strong>Do prazo de vigência: </strong></p>
                        <p class="text-justify"><strong>Cláusula 41ª – </strong>O prazo para utilização do QualityManager ®
                            é definido por tempo indeterminado, ou seja, a Licenciada pode utilizar o QualityManager ® pelo
                            tempo que desejar, devendo durante o tempo que estiver utilizando o QualityManager ® se manter
                            adimplente com o pagamento de sua licença. </p>


                        <p class="text-justify"><strong>Da rescisão: </strong></p>
                        <p class="text-justify"><strong>Cláusula 42ª – </strong>A Licenciada pode rescindir este
                            contrato a qualquer momento, desde que informe expressamente a Licenciante com antecedência
                            de 30 dias.</p>
                        <p class="text-justify"><strong>Cláusula 43ª – </strong>Em nenhuma hipótese serão devolvidos
                            quaisquer valores pagos.</p>
                        <p class="text-justify"><strong>Cláusula 44ª – </strong>A Licenciante pode rescindir este
                            contrato no caso do não cumprimento pela Licenciada de suas obrigações contratuais.</p>
                        <p class="text-justify"><strong>Cláusula 45ª – </strong>O Banco de dados da Licenciada estará
                            disponível para download, se solicitado, durante os 30 dias de aviso prévio.</p>


                        <p class="text-justify"><strong>Das condições gerais: </strong></p>
                        <p class="text-justify"><strong>Cláusula 46ª –</strong> Fica estabelecido também que a
                            Licenciante empregará sempre seus melhores esforços na manutenção e na evolução tecnológica
                            do QualityManager ® e do servidor que a hospeda.</p>
                        <p class="text-justify"><strong>Cláusula 47ª –</strong> Em hipótese alguma a Licenciante será
                            responsável por lucros cessantes, indenizações de qualquer espécie, danos comerciais,
                            especiais, acidentais, consequenciais ou outros diretos ou indiretos.</p>
                        <p class="text-justify"><strong>Cláusula 48ª –</strong> A utilização do QualityManager ® fica
                            sujeita as limitações oferecidas de acordo com o plano contratado.</p>
                        <p class="text-justify"><strong>Cláusula 49ª –</strong> A Licenciante se reserva ao direito de
                            modificar este contrato de acordo com sua necessidade, com intuito de preservar sua
                            propriedade intelectual e o bom funcionamento de todo o sistema.</p>
                        <p class="text-justify"><strong>Cláusula 50ª –</strong> A Licenciante pode a qualquer tempo,
                            suspender ou retirar o acesso da Licenciada se houver publicação ou informação que esteja
                            desrespeitando as regras de conteúdo aqui estabelecidas ou as normas legais em vigor, sem
                            qualquer devolução de quantias pagas pela Licenciada.</p>
                        <p class="text-justify"><strong>Cláusula 51ª –</strong> Em nenhuma hipótese a Licenciada terá
                            acesso ao código fonte do sistema ora licenciado, por este se tratar de propriedade
                            intelectual da Licenciante.</p>
                        <p class="text-justify"><strong>Cláusula 52ª –</strong> Não estão inclusos neste contrato a
                            realização de treinamentos presenciais e visitas técnicas, caso seja necessário vossa
                            empresa deverá solicitar proposta específica.</p>
                        <p class="text-justify"><strong>Cláusula 53ª –</strong> O espaço para armazenamento em banco de
                            dados é ilimitado, mas o espaço em disco para uploads de arquivos externos é limitado a
                            500MB por licença.</p>
                        <p class="text-justify"><strong>Cláusula 54ª –</strong> Diariamente, após as 22 horas, e durante
                            os finais de semana e feriados, os serviços estão sujeitos a paralizações para manutenção,
                            atualizações, backups e demais procedimentos de segurança.</p>


                        <p class="text-justify"><strong>Do termo de confidencialidade: </strong></p>
                        <p class="text-justify"><strong>Cláusula 55ª – </strong>As partes se comprometem manter sigilo
                            sobre toda e qualquer informação técnica ou não-técnica relativa à solução, seus
                            equipamentos, infraestrutura, sua utilização, senhas de acesso ao sistema, senhas de conexão
                            a serviços prestados por terceiros e outras informações necessárias para operação integrada
                            da solução QualityManager ®, objeto deste acordo.</p>
                        <p class="text-justify"><strong>Cláusula 56ª – </strong>Nenhuma das partes poderá prestar
                            informações a terceiros, nem permitir que terceiros tenham acesso a quaisquer informações
                            que administrem, acessem e/ou controlem relacionados a solução QualityManager ®.</p>
                        <p class="text-justify"><strong>Cláusula 57ª – </strong>As partes obrigam-se a manter o mais
                            absoluto sigilo e confidencialidade, no tocante a solução, aos serviços solicitados e
                            executados, documentação e todas as informações verbais ou escritas, registradas e
                            particulares, segredos de negócios ou qualquer outra informação uma da outra a que tiverem
                            acesso, durante a vigência do presente termo ou após este rescindido, tanto no ambiente de
                            trabalho, como nas dependências de qualquer cliente indicado, não podendo utilizar ou deles
                            dar conhecimento a qualquer terceiro ou estranho a este termo.</p>
                        <p class="text-justify"><strong>Cláusula 58ª – </strong>As partes, expressamente reconhecem que
                            todos os documentos de qualquer natureza que tenham sido utilizados, criados ou estando sob
                            o seu controle, será igualmente resguardado pelo compromisso de sigilo e confidencialidade.
                        </p>
                        <p class="text-justify"><strong>Cláusula 59ª – </strong>As partes declaram expressamente saber
                            que a confidencialidade e o sigilo são essenciais a relação comercial/técnica entre elas.
                        </p>
                        <p class="text-justify"><strong>Cláusula 60ª – </strong>Todo documento, informação ou parte
                            referente aos serviços do QualityManager ® não poderão ser divulgados a terceiros sem que haja
                            uma autorização formal da parte detentora dos direitos sobre eles.</p>
                        <p class="text-justify"><strong>Cláusula 61ª – </strong>É de inteira responsabilidade de cada
                            uma das partes o sigilo interno das informações contidas em qualquer documento ou parte
                            envolvida do sistema, se responsabilizando pela má utilização das informações oriundas deste
                            acordo pelos seus funcionários ou terceiros por elas nomeados.</p>
                        <p class="text-justify"><strong>Cláusula 62ª – </strong>Nenhuma parte da solução poderá ser
                            utilizada em outras soluções ou sistemas que as partes possam vir a fazer ou ter.</p>
                        <p class="text-justify"><strong>Cláusula 63ª – </strong>Mesmo após o término deste Contrato, as
                            partes de comprometem manter sigilo de todas as informações a elas confiadas.</p>


                        <p class="text-justify"><strong>Faturamento e Cobrança: </strong></p>
                        <p class="text-justify"><strong>Cláusula 64ª – </strong>A Empresa DESENVAZ SOLUCOES EM
                            TECNOLOGIA, inscrita no CNPJ sob o número 50.219.996/0001-07, doravante denominada "Empresa
                            de Faturamento e cobranças", será responsável por todas as atividades relacionadas à
                            cobrança e faturamento dos serviços prestados pela Licenciante à Licenciada, conforme
                            estabelecido neste contrato.</p>
                        <p class="text-justify"><strong>Cláusula 65ª – </strong>A Empresa de Faturamento e cobranças
                            atuará em nome da Licenciante para realizar as devidas cobranças e garantir o recebimento
                            dos valores devidos pelos serviços prestados.</p>
                        <p class="text-justify"><strong>Cláusula 66ª – </strong>Para fins de pagamento dos serviços
                            prestados, a Licenciada deverá efetuar os pagamentos diretamente à Empresa de Faturamento e
                            cobranças, utilizando os seguintes dados bancários, Banco: 655 – BCO VOTORANTIM S.A.
                            Agência: 1111, Conta: 62262551479</p>
                        <p class="text-justify" style="padding-left: 5%;"><strong>Cláusula única – </strong>Empresa de
                            Cobranças disponibiliza a opção de pagamento via PIX, utilizando o CNPJ 50.219.996/0001-07.
                        </p>
                        <p class="text-justify"><strong>Cláusula 67ª –</strong> A Licenciada concorda em realizar os
                            pagamentos devidos dentro dos prazos estabelecidos pela Empresa de Faturamento e cobranças e
                            assume total responsabilidade pelo cumprimento das obrigações financeiras conforme acordado
                            neste contrato.</p>
                        <p class="text-justify"><strong>Cláusula 68ª –</strong> Qualquer comunicação relacionada às
                            cobranças, faturamento ou pagamentos deverá ser realizada diretamente com a Empresa de
                            Cobranças, utilizando os canais de contato disponibilizados por ela.</p>
                        <p class="text-justify"><strong>Cláusula 69ª –</strong> A Licenciante e a Licenciada concordam
                            que a Empresa de Faturamento e cobranças é uma terceira parte envolvida neste contrato,
                            atuando de forma independente e sem qualquer vínculo empregatício ou societário com ambas as
                            partes.</p>
                        <p class="text-justify"><strong>Cláusula 70ª –</strong> Ambas as partes reconhecem que a Empresa
                            de Faturamento e cobranças possui total autonomia para gerir as atividades de cobrança,
                            faturamento e recebimento dos valores devidos pelos serviços prestados, não cabendo à
                            Licenciante qualquer responsabilidade direta por eventuais falhas ou atrasos nas atividades
                            desempenhadas pela Empresa de Cobranças.</p>
                        <p class="text-justify"><strong>Cláusula 71ª –</strong> Quaisquer disputas ou divergências
                            relacionadas às cobranças, faturamento ou pagamentos deverão ser resolvidas diretamente
                            entre a Licenciada e a Empresa de Faturamento e cobranças, não envolvendo a Licenciante,
                            exceto nos casos em que a Licenciante esteja diretamente envolvida em questões contratuais
                            específicas.</p>

                        <p class="text-justify"><strong>Reajuste: </strong></p>
                        <p class="text-justify"><strong>Cláusula 72ª –</strong>O presente contrato estabelece que o
                            valor da mensalidade do serviço fornecido será reajustado anualmente com base no Índice
                            Geral de Preços do Mercado (IGP-M), divulgado pela Fundação Getúlio Vargas (FGV). </p>
                        <p class="text-justify"><strong>Cláusula 73ª –</strong>O IGP-M será utilizado como referência
                            para o cálculo do reajuste anual, com o objetivo de manter o valor do contrato alinhado com
                            a variação média dos preços no mercado. </p>
                        <p class="text-justify"><strong>Cláusula 74ª –</strong>O reajuste anual será aplicado com base
                            na data de liberação do acesso ao sistema, considerando a variação acumulada do IGP-M no
                            período compreendido entre o último reajuste e a data atual. </p>
                        <p class="text-justify"><strong>Cláusula 75ª –</strong> Variação acumulada do IGP-M é a variação
                            percentual acumulada do IGP-M desde o último reajuste. A variação acumulada do IGP-M será
                            obtida com base nos dados publicados pela FGV ou pelo Índice Nacional de Preços ao
                            Consumidor Amplo (IPCA), divulgado pelo Instituto Brasileiro de Geografia e Estatística
                            (IBGE).</p>
                        <p class="text-justify"><strong>Cláusula 76ª –</strong>As partes concordam em que o reajuste
                            anual com base no IGP-M é uma prática justa e razoável para manter o equilíbrio
                            econômico-financeiro do contrato. </p>


                        <p class="text-justify"><strong>Das disposições finais: </strong></p>
                        <p class="text-justify"><strong>Cláusula 77ª –</strong> O presente contrato não vincula qualquer
                            das partes em obrigações adicionais, nem concede direitos adicionais, que não àqueles
                            expressamente previstos neste instrumento, ou que sejam decorrentes do contido em seu texto.
                        </p>
                        <p class="text-justify"><strong>Cláusula 78ª –</strong> A Licenciante poderá usar a marca da
                            Licenciada e criar cases para divulgar e comunicar os serviços contratados neste para
                            efeitos de divulgação de marketing e mídia.</p>
                        <p class="text-justify"><strong>Cláusula 79ª –</strong> Fica acordado que a Licenciada não
                            poderá contratar qualquer funcionário, prestador de serviços, pessoa jurídica ou mesmo
                            qualquer pessoa que tenha relações técnicas com a Licenciante sem o intermédio desta
                            (Licenciante) e que se assim o fizer no prazo inferior ou igual a 700 dias, mesmo após a
                            rescisão do funcionário com a Licenciante, caberá à Licenciada pagar para a Licenciante o
                            valor equivalente a 100 salários-mínimos conforme sindicato de classe.</p>
                        <p class="text-justify"><strong>Cláusula 80ª –</strong> A Licenciante não possui um Data
                            Protection Impact Assessment, o DPIA, pois os dados coletados não são dados pessoais
                            sensíveis e não resultam em um alto risco aos direitos e liberdades das pessoas físicas.</p>
                        <p class="text-justify"><strong>Cláusula 81ª –</strong> Em conformidade ao LGPD, Lei geral de
                            proteção de dados, a Licenciante disponibiliza um canal de comunicação dentro da aplicação
                            para que os seus usuários, colaboradores da Licenciada, possam efetuar solicitações
                            relativas aos seus dados pessoais.</p>
                        <p class="text-justify"><strong>Cláusula 82ª –</strong> Os casos omissos serão resolvidos de
                            comum acordo, mediante reunião das partes para tal finalidade, devendo ser elaborado termo
                            aditivo a este contrato e assinado pelas partes.</p>


                        <p class="text-justify"><strong>Foro: </strong></p>
                        <p class="text-justify"><strong>Cláusula 83ª – </strong>Fica eleito, desde já, com exclusão de
                            qualquer outro, por mais privilegiado que seja, o foro Central da Comarca da Cidade de São
                            Paulo – SP, para dirimir toda e qualquer dúvida ou pendência oriunda deste contrato.</p>


                        <!-- <p class="text-right">São Paulo, <? echo $formatter->format($date); ?></p> -->
                        <hr>
                        <div class="row">
                            <div class="col-md-6">
                            </div>
                            <div class="col-md-6">
                                <p class="text-right">
                                    <strong>Histórico de Revisões</strong><br><strong>Rev. 1.0 </strong> - 11-05-22 -
                                    Alteração nos dados da Licenciante, nomeadamente a razão social e CNPJ da
                                    empresa.<br>
                                    <strong>Rev. 2.0 </strong> - 24-05-22 - Revisão geral dos termos de utilização.<br>
                                    <strong>Rev. 3.0 </strong> - 12-05-23 - Revisão geral dos termos de utilização e
                                    renovação.<br>
                                    <strong>Rev. 4.0 </strong> - 01-07-23 - Revisão geral dos termos de utilização,
                                    fatuamento e reajuste.
                                </p>
                            </div>
                        </div>



                    </div>
                </div>
            </div>

        </div>

        <app-footer></app-footer>
    </div>