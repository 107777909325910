<div class="page">
    <app-header></app-header>
    <div class="headerHeight"></div>

    <!-- part 1 -->
    <div class="content">
        <div style="height: 1px;"></div>

        <div class="container xs-10">

            <div class="row flex-center">

                <div class="col-md-4 col-md-offset-1 col-sm-12 col-xs-12">
                    <div class="lgpd-section-title">
                        <h1 class="text-left title">Conheça o<br class="d-none d-lg-flex"> nosso time</h1>
                        <p class="text-left subTitle">Se você quiser ir rápido,<br class="d-none d-lg-flex"> vá sozinho. Se você quer<br class="d-none d-lg-flex"> ir longe, <b>vamos juntos</b>!</p>
                    </div>
                </div>

                <div class="col-md-7  col-sm-12 col-xs-12">
                    <div class="row d-flex flex-center">
                        <app-team-person foto="assets/team/time-11.webp" nome="Domingos Ribeiro" cargo="CEO" email="djr@qualitymanager.com.br" linkedin="https://www.linkedin.com/in/domribeiro/" class="col-6 col-md-4"></app-team-person>
                        <!-- <app-team-person foto="assets/team/time-2.webp" nome="Jessica Sousa" cargo="COO" email="jessica@qualitymanager.com.br" linkedin="https://www.linkedin.com/in/jessica-sousa-65261042/" class="col-md-3 col-sm-6"></app-team-person> -->
                        <app-team-person foto="assets/team/time-3.webp" nome="Vitor Vaz" cargo="CBO" email="vitor@qualitymanager.com.br" linkedin="https://www.linkedin.com/in/vitor-vaz/" class="col-6 col-md-4"></app-team-person>
                        <app-team-person foto="assets/team/time-5.webp" nome="Daniela Ribeiro" cargo="CFO" email="financeiro@qualitymanager.com.br" linkedin="https://www.linkedin.com/in/daniela-ribeiro-03706496/" class="col-6 col-md-4"></app-team-person>

                        <app-team-person foto="assets/team/time-4.webp" nome="Daniel Saluceste" cargo="Developer" class="col-6 col-md-4"></app-team-person>
                        <app-team-person foto="assets/team/time-1.webp" nome="Bianca Ribeiro" cargo="Marketing" class="col-6 col-md-4"></app-team-person>
                        <!-- <app-team-person foto="assets/team/time-9.webp" nome="Francesca Scapprino" cargo="Marketing" class="col-md-3 col-sm-6"></app-team-person> -->
                        <!-- <app-team-person foto="assets/team/time-6.webp" nome="Hugo Gomes" cargo="Developer" class="col-md-3 col-sm-6"></app-team-person> -->
                        <app-team-person foto="assets/team/time-7.webp" nome="Gabriel Silva" cargo="Developer" class="col-6 col-md-4"></app-team-person>

                        <app-team-person foto="assets/team/time-10.webp" nome="Nayara Cunha" cargo="Business Development" class="col-6 col-md-4"></app-team-person>
                        <app-team-person foto="assets/team/time-8.webp" nome="Camila Medeiros" cargo="Business Development" class="col-6 col-md-4"></app-team-person>
                        <app-team-person foto="assets/team/time-12.webp" nome="Pedro Vaz" cargo="Marketing" class="col-6 col-md-4"></app-team-person>
                    </div>
                </div>

            </div>
        </div>
        
    </div>

    <app-footer></app-footer>
</div>