<div class="header">
    <div class="container d-flex h-100">
        <div class="d-flex h-100 w-100" style="justify-content: space-between;">
            <!-- left -->
            <div class="d-flex h-100 align-items-center">
                <img [routerLink]="['/']" src="assets/images/logo.webp" alt="Logo do QualityManager" style="cursor: pointer;" width="239" height="58" class="logo">
            </div>
            <!-- right -->
            <div class="d-flex h-100 align-items-center">
                <!-- xl -->
                <div class="d-none d-xl-flex">
                    <div class="btnTxt" (click)="goPage(1)">
                        <span>O que é?</span>
                        <div></div>
                    </div>
                    <div class="btnTxt" (click)="goPage(2)">
                        <span>Diferenciais</span>
                        <div></div>
                    </div>
                    <div class="btnTxt" (click)="goPage(3)">
                        <span>Porque o Q1</span>
                        <div></div>
                    </div>
                    <div class="btnTxt" (click)="goPage(4)">
                        <span>Planos</span>
                        <div></div>
                    </div>
                    <div class="btnTxt" (click)="goPage(5)">
                        <span>Contato</span>
                        <div></div>
                    </div>
                    <div class="btnTxt" (click)="goLink('https://blog.qualitymanager.com.br/')">
                        <span>Blog</span>
                        <div></div>
                    </div>
                    <!-- <div class="langBtn">
                        <img loading="lazy" src="assets/images/brazil.webp" style="border-radius: 100%; margin-right: 4px;" alt="">
                        <span>BR</span>
                        <ion-icon name="chevron-down-outline"></ion-icon>
                        <div class="triangulo-para-cima"></div>
                        <div class="selectLangPc">
                            <div class="pcLangItem" routerLinkActive="menuItemActive">
                                <img loading="lazy" style="border-radius: 100%;" width="17px" src="assets/images/brazil.webp" alt="Brasil">
                                <span>Português Brasil</span>
                            </div>
                            <div class="pcLangItem" routerLinkActive="menuItemActive">
                                <img loading="lazy" src="assets/images/eua.webp" width="17px" alt="EUA">
                                <span>English</span>
                            </div>
                            <div class="pcLangItem" routerLinkActive="menuItemActive">
                                <img loading="lazy" src="assets/images/italy.webp" alt="Italia">
                                <span>Italian</span>
                            </div>
                        </div>
                    </div> -->
                    <button [routerLink]="['/testeja']" class="button" style="margin-right: 8px;">TESTE JÁ!</button>
                    <!-- <button (click)="login()" class="buttonOutline">LOGIN</button> -->
                </div>
                <!-- sm - md - lg -->
                <ion-icon class="menuIcon d-xl-none" style="color: white;" name="menu-outline" (click)="menuOpen = !menuOpen"></ion-icon>
            </div>
        </div>
    </div>
</div>

<div class="backdrop {{menuOpen == true ? 'backdropOpen' : ''}}" (click)="menuOpen = false"></div>
<div class="menu {{menuOpen == true ? 'menuOpen' : ''}}">

    <!-- <div class="closeBtn">
        <ion-icon name="close-sharp"></ion-icon>
    </div> -->

    <div class="w-100 d-flex align-items-center justify-content-center p-4">
        <img src="assets/images/logo.webp" alt="Logo do QualityManager" width="239" height="58" class="logo" style="margin-right: 11px;">
    </div>

    <div class="menuItem" (click)="goPage(1)">
        <ion-icon name="help-outline"></ion-icon>
        <span>O que é?</span>
    </div>

    <div class="menuItem" (click)="goPage(2)">
        <ion-icon name="list-outline"></ion-icon>
        <span>Diferenciais</span>
    </div>

    <div class="menuItem" (click)="goPage(3)">
        <ion-icon name="document-outline"></ion-icon>
        <span>Porque o Q1</span>
    </div>

    <div class="menuItem" (click)="goPage(4)">
        <ion-icon name="apps-outline"></ion-icon>
        <span>Planos</span>
    </div>

    <div class="menuItem" (click)="goPage(5)">
        <ion-icon name="mail-outline"></ion-icon>
        <span>Contato</span>
    </div>

    <div class="menuItem" (click)="goLink('https://blog.qualitymanager.com.br/')">
        <ion-icon name="document-text-outline"></ion-icon>
        <span>Blog</span>
    </div>

    <!-- <div class="menuLastPart">
        <img loading="lazy" class="band" src="assets/images/br.svg" alt="BR">
        <span class="lang">Português Brasil</span>
    </div> -->
</div>