<div style="background-color: #f1f1f1;">
    <app-header></app-header>
    <div class="headerHeight"></div>

    <div class="content before" [formGroup]="form">
        <div class="container">
            <div class="row">
                <div class="col-lg" style="display: flex; justify-content: center; flex-direction: column;">
                    <span class="p-title mt-5">VAMOS LÁ?</span>
                    <span class="p-subtitle mb-5">Transforme sua <b>empresa</b> com o <b>QualityManager</b>! Preencha seus dados e descubra como podemos revolucionar seus resultados.</span>
                  </div>
                  <div class="col-lg">
                        <div class="form">
                            <span class="f-title">Fale com um especialista</span>
                            <span class="f-subtitle">Preencha os campos abaixo e nosso time entrará em contato prontamente via email:</span>

                            <div class="w-100 row mt-2 gap-3" style="padding-left: 10px;">
                                <div class="col-lg" style="padding: 0;">
                                    <span class="label">Nome</span>
                                    <input type="text" placeholder="Nome..." class="inputq" maxlength="60" formControlName="nome">
                                </div>
                                <div class="col-lg" style="padding: 0;">
                                    <span class="label">E-Mail</span>
                                    <input type="text" placeholder="E-Mail..." class="inputq" maxlength="60" formControlName="email">
                                </div>
                            </div>

                            <div class="w-100 row gap-3" style="padding-left: 10px;">
                                <div class="col-lg" style="padding: 0;">
                                    <span class="label">Telefone</span>
                                    <input type="text" placeholder="Telefone..." class="inputq" mask="(00) 00000-0009" formControlName="telefone">
                                </div>
                                <div class="col-lg" style="padding: 0;">
                                    <span class="label">Empresa</span>
                                    <input type="text" placeholder="Empresa..." class="inputq" maxlength="60" formControlName="empresa">
                                </div>
                            </div>

                            <span *ngIf="error" class="error">{{error}}</span>

                            <div class="d-flex mt-4 mb-3">
                                <input class="checkbox" type="checkbox" id="terms" name="terms" formControlName="termos" />
                                <label for="terms">Li e concordo com os termos e condições, <a [routerLink]="['/termos-e-condicoes']">acesse aqui.</a></label>
                            </div>

                            <div class="d-flex" style="margin-bottom: 30px;">
                                <input class="checkbox" type="checkbox" id="termsB" name="termsB" formControlName="termosB" />
                                <label for="termsB">Li e concordo com as políticas de privacidade, <a href="https://app.lgpd.center/cyber/portal/QualityManager#privacidade" target="_blank">acesse aqui.</a></label>
                            </div>

                            <button (click)="sendForm()" class="button w-100">Enviar</button>
                        </div>
                  </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</div>