import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  pageChange: EventEmitter<number> = new EventEmitter();

  constructor() { }
}
