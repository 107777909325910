import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import Swiper from 'swiper';
import { SwiperOptions } from 'swiper/types';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit, AfterViewInit {

  @ViewChild('swiperRef') swiperRef: ElementRef | any;

  public swiper?: Swiper;

  form1 = new FormGroup({
    // step1
    nome_completo: new FormControl('', [Validators.required]),
    cnpj: new FormControl('', [Validators.required]),
    contato1: new FormControl('', [Validators.required]),
    ramal: new FormControl('', []),
    contato2: new FormControl(''),
    email: new FormControl('', [Validators.required]),
    razao_social: new FormControl('', [Validators.required]),
    site: new FormControl('', [Validators.required]),
    nome_assina: new FormControl('', [Validators.required]),
    email_assina: new FormControl('', [Validators.required, Validators.email]),
  });

  form1p2 = new FormGroup({
    rua: new FormControl('', [Validators.required]),
    estado: new FormControl('', [Validators.required]),
    complemento: new FormControl('', []),
    cidade: new FormControl('', [Validators.required]),
    bairro: new FormControl('', [Validators.required]),
    cep: new FormControl('', [Validators.required]),
    num_endereco: new FormControl('', [Validators.required]),
  });

  form2 = new FormGroup({
    // step2
    plan: new FormControl(''),
    qtd: new FormControl(''),
    implementacao: new FormControl(0),
    renovacao: new FormControl(''),
    startup_check1: new FormControl(false),
    startup_check2: new FormControl(false),
    startup_check3: new FormControl(false),
  });

  form3 = new FormGroup({
    // step3
    check1: new FormControl(false),
    check2: new FormControl(false),
  });

  form4 = new FormGroup({
    // cartão de credito
    card_number: new FormControl(''),
    card_name: new FormControl(''),
    card_month_year: new FormControl(''),
    card_cvv: new FormControl(''),
    pay_type: new FormControl('1'),
  });

  public plans: any = [];
  public prazos: any = [];
  public valorImplementacao = 0;

  public step = 1;

  public selectedPayOption = 1;

  public pixData: any;

  public errorPart1 = false;
  public errorPart1Message = '';

  public Part1p2Loading = false;
  public errorPart1p2 = false;
  public errorPart1p2Message = '';

  public errorPart2 = false;
  public errorPart2Message = '';

  public errorPart3 = false;
  public errorPart3Message = '';

  public errorPart4 = false;
  public errorPart4Message = '';

  public loadingCEP = false;

  public loadingCard = false;

  public website = '';

  private env = environment.baseURL;

  constructor(public http: HttpClient) {
    // scroll up
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngAfterViewInit(): void {
    const swiperEl = Object.assign(this.swiperRef.nativeElement, {
      allowTouchMove: false,
      preventInteractionOnTransition: true,
      keyboard: {
        enabled: true,
      },
    });
    swiperEl.initialize();

    this.swiper = this.swiperRef?.nativeElement.swiper;

    // disable keyboard tab btn navigation
    this.swiperRef?.nativeElement.swiper.keyboard.disable();
  }

  ngOnInit() {

    this.http.get<any>(this.env + '/api/plano').subscribe({
      next: data => {
        this.plans = data?.planos;
        // remove plan startup
        this.plans = this.plans.filter((e: any) => e.chave != 'startup');
        this.valorImplementacao = Number(data?.valor_implementacao.valor_implementacao);
        this.prazos = data?.prazo_valor;
      },
      error: error => {
        console.error('There was an error!', error);
      }
    })

  }

  getValue(e: any) {

    if (e.chave == 'enterprise') {
      return 'consulte';
    }

    if (e.valor == '0.00') {
      return 'grátis';
    }

    return 'a partir de R$' + e.valor;
  }

  getQtdAccess(key: any) {
    let plan = this.plans.find((e: any) => e.chave == key);
    return plan?.valores_por_acesso;
  }

  getValueFromAccess(access_qtd: any) {
    let planKey = this.form2.get('plan')?.value;
    let plan = this.plans.find((e: any) => e.chave == planKey);

    let value = access_qtd * plan?.valor;

    if (value == 0) {
      return '- grátis';
    } else {
      return '- R$' + access_qtd * plan?.valor;
    }
  }

  changePlan() {
    this.form2.controls['qtd'].setValue('');
  }

  backStep() {
    if (this.step == 1) {
      return;
    }

    if (this.step == 1.5) {
      this.step = 1;
      this.swiperRef?.nativeElement.swiper.slidePrev();
      // scroll up
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }
    if (this.step == 2) {
      this.step = 1.5;
      this.swiperRef?.nativeElement.swiper.slidePrev();
      // scroll up
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }

    this.step--;
    this.swiperRef?.nativeElement.swiper.slidePrev();
    // scroll up
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  nextStep() {
    // Step 1
    if (this.step == 1) {

      // let site: string = this.form1.get('site')?.value ?? '';
      // site = site.replace('http://', '');
      // site = site.replace('https://', '');
      // site = site.replace('www.', '');
      // site = site.split('/')[0];
      // site = site.replace('/', '');      
    
      this.errorPart1 = false;
      this.form1.markAllAsTouched()
      if (this.form1.invalid) {
        this.errorPart1 = true;
        setTimeout(() => {
          this.errorPart1 = false;
        }, 5000);
        this.errorPart1Message = 'Preencha todos os campos obrigatórios';
        return;
      }

      // verify cnpj
      if (this.verifyCNPJ(this.form1.get('cnpj')?.value) == false) {
        this.errorPart1 = true;
        setTimeout(() => {
          this.errorPart1 = false;
        }, 5000);
        this.errorPart1Message = 'CNPJ inválido';
        return;
      }

      let site: string = this.form1.get('site')?.value ?? '';
      site = site.replace('http://', '');
      site = site.replace('https://', '');
      site = site.replace('www.', '');
      site = site.split('/')[0];
      site = site.replace('/', '');

      // verifica se é um site válido, se possuit . e se possui pelo menos 2 caracteres depois do ultimo ponto
      if (site.includes('.') == false || site.split('.').length < 2) {
        this.errorPart1 = true;
        setTimeout(() => {
          this.errorPart1 = false;
        }, 5000);
        this.errorPart1Message = 'Site inválido';
        return;
      }
      
      // email domain needs to be the same as the site domain
      let email: string = this.form1.get('email')?.value ?? '';
      email = email + "@" + this.website;
      email = email.split('@')[1];
      if (email != site) {
        this.errorPart1 = true;
        setTimeout(() => {
          this.errorPart1 = false;
        }, 7000);
        this.errorPart1Message = 'Por favor, observe que o domínio de e-mail informado deve corresponder ao domínio do site.';
        return;
      }

      // depois do ultimo ponto precisa ter pelo menos 2 caracteres
      let emailDomain2: string = this.form1.get('email')?.value ?? '';
      emailDomain2 = emailDomain2 + "@" + this.website;
      emailDomain2 = emailDomain2.split('@')[1];
      emailDomain2 = emailDomain2.split('.')[1];
      if (emailDomain2.length < 2) {
        this.errorPart1 = true;
        setTimeout(() => {
          this.errorPart1 = false;
        }, 7000);
        this.errorPart1Message = 'Domínio de e-mail informado é inválido.';
        return;
      }

      // 
      let emailPart: string = this.form1.get('email')?.value ?? '';
      if (emailPart.includes('@')) {
        this.errorPart1 = true;
        setTimeout(() => {
          this.errorPart1 = false;
        }, 7000);
        this.errorPart1Message = 'O e-mail informado é inválido.';
        return;
      }
      
      // depois do ultimo . do email não pode conter numeros
      let emailDomain: string = this.form1.get('email')?.value ?? '';
      emailDomain = emailDomain + "@" + this.website;
      emailDomain = emailDomain.split('@')[1];
      emailDomain = emailDomain.split('.')[1];
      if (emailDomain.match(/\d+/g) != null) {
        this.errorPart1 = true;
        setTimeout(() => {
          this.errorPart1 = false;
        }, 7000);
        this.errorPart1Message = 'Domínio de e-mail informado é inválido.';
        return;
      }

      if (this.validateCNPJ(this.form1.get('cnpj')?.value) == false) {
        this.errorPart1 = true;
        setTimeout(() => {
          this.errorPart1 = false;
        }, 5000);
        this.errorPart1Message = 'CNPJ inválido';
        return;
      }

      this.step = 1.5;
      this.swiperRef?.nativeElement.swiper.slideTo(1);
      // scroll up
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      return;
    }

    // Step 1.5
    if (this.step == 1.5) {

      if (this.Part1p2Loading == true) {
        return;
      }

      this.errorPart1p2 = false;
      this.form1p2.markAllAsTouched()
      if (this.form1p2.invalid) {
        this.errorPart1p2 = true;
        setTimeout(() => {
          this.errorPart1p2 = false;
        }, 5000);
        this.errorPart1p2Message = 'Preencha todos os campos obrigatórios';
        return;
      }

      let body = {
        nome_completo: this.form1.get('nome_completo')?.value,
        bairro: this.form1p2.get('bairro')?.value,
        cep: this.form1p2.get('cep')?.value,
        cidade: this.form1p2.get('cidade')?.value,
        complemento: this.form1p2.get('complemento')?.value,
        cnpj: this.form1.get('cnpj')?.value,
        contato1: this.form1.get('contato1')?.value,
        ramal: this.form1.get('ramal')?.value,
        contato2: this.form1.get('contato2')?.value,
        email: this.form1.get('email')?.value + '@' + this.website,
        estado: this.form1p2.get('estado')?.value,
        num_endereco: this.form1p2.get('num_endereco')?.value,
        razao_social: this.form1.get('razao_social')?.value,
        nome_assina: this.form1.get('nome_assina')?.value,
        email_assina: this.form1.get('email_assina')?.value,
        rua: this.form1p2.get('rua')?.value,
        site: this.form1.get('site')?.value,
      };
      this.Part1p2Loading = true;
      this.http.post<any>(this.env + '/api/cliente', body).subscribe({
        next: data => {
        this.Part1p2Loading = false;
          this.step = 2;
          this.swiperRef?.nativeElement.swiper.slideTo(2);
        },
        error: error => {
          this.Part1p2Loading = false;
          console.error('There was an error!', error);
          console.log(Object.keys(error.error.errors));
          this.errorPart1p2 = true;
          setTimeout(() => {
            this.errorPart1p2 = false;
          }, 5000);
          try {
            this.errorPart1p2Message = error.error.errors[Object.keys(error.error.errors)[0]];
          } catch (error) {}
          if (this.errorPart1p2Message.length < 5) {
            this.errorPart1p2Message = 'Ocorreu um erro. Tente novamente mais tarde.';
          }
        }
      })

      return;
    }

    // Step 2
    if (this.step == 2) {

      this.errorPart2 = false

      if (this.form2.get('plan')?.value == '') {
        this.errorPart2 = true;
        this.errorPart2Message = 'Selecione um plano';
        setTimeout(() => {
          this.errorPart2 = false;
        }, 5000);
        return;
      }

      // if (this.form2.get('plan')?.value != 'enterprise' && this.form2.get('plan')?.value != 'startup') {
      //   if (this.form2.get('qtd')?.value == '') {
      //     this.errorPart2 = true;
      //     this.errorPart2Message = 'Selecione a quantidade de acessos';
      //     setTimeout(() => {
      //       this.errorPart2 = false;
      //     }, 5000);
      //     return;
      //   }
      // }

      // if (this.form2.get('plan')?.value != 'enterprise' && this.form2.get('plan')?.value != 'startup') {
      //   if (this.form2.get('renovacao')?.value == '') {
      //     this.errorPart2 = true;
      //     this.errorPart2Message = 'Selecione o período de renovação';
      //     setTimeout(() => {
      //       this.errorPart2 = false;
      //     }, 5000);
      //     return;
      //   }
      // }

      if (this.form2.get('plan')?.value != 'enterprise' && this.form2.get('plan')?.value != 'startup') {
        if (this.form2.get('implementacao')?.value == 0) {
          this.errorPart2 = true;
          this.errorPart2Message = 'Selecione se deseja implementação';
          setTimeout(() => {
            this.errorPart2 = false;
          }, 5000);
          return;
        }
      }

      if (this.form2.get('plan')?.value == 'startup') {
        if (this.form2.controls.startup_check1.value != true || this.form2.controls.startup_check2.value != true || this.form2.controls.startup_check3.value != true) {
          this.errorPart2 = true;
          this.errorPart2Message = 'Você precisa aceitar os termos para continuar!';
          setTimeout(() => {
            this.errorPart2 = false;
          }, 5000);
          return;
        }
      }

      if (this.form2.get('plan')?.value != 'enterprise' && this.form2.get('plan')?.value != 'startup') {
        if (this.form2.controls.implementacao.value == 2) {
          Swal.fire({
            width: 800,
            title: '🚨 Não deixe essa oportunidade passar! 🚨',
            html: '<p>Antes de prosseguir com a adesão, queremos garantir que você tenha considerado cuidadosamente uma opção valiosa: <b>nosso apoio especializado na implementação.</b></p> <br> <p>Pense nisso: 100% das empresas que optam por contar com a orientação dos nossos consultores conseguem implementar com sucesso. Ao utilizar nossos serviços, você terá acesso a uma equipe experiente e dedicada, pronta para ajudá-lo em cada etapa do processo.</p> <br> <p>Sabemos que a implementação pode ser um desafio, mas estamos aqui para torná-la mais fácil e eficiente para você. Então, antes de tomar sua decisão final, considere o apoio dos nossos consultores e alcance o máximo potencial com nosso serviço.</p> <br> <p>Lembre-se: estamos ao seu lado para garantir o sucesso do seu projeto! 💼🚀</p>',
            showCancelButton: true,
            confirmButtonText: 'Sim, quero implementação!',
            cancelButtonText: 'Não, não quero ajuda!',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#e74c3c',
          }).then((result) => {
            if (result.isConfirmed) {
              setTimeout(() => {
              this.form2.controls['implementacao'].setValue(1);
              this.swiperRef?.nativeElement.swiper.slideTo(3);
              // scroll up
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
              }, 500);
            } else {
              setTimeout(() => {
              this.step = 3;
              this.swiperRef?.nativeElement.swiper.slideTo(3);
              // scroll up
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
              }, 500);
            }
          });
          return;
        }
      }

      this.step = 3;
      this.swiperRef?.nativeElement.swiper.slideTo(3);
      // scroll up
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      return;
    }

    // Step 3
    if (this.step == 3) {
      this.errorPart3 = false;

      if (this.form3.controls.check1.value != true || this.form3.controls.check2.value != true) {
        this.errorPart3 = true;
        this.errorPart3Message = 'Você precisa aceitar os termos para continuar!';
        setTimeout(() => {
          this.errorPart3 = false;
        }, 5000);
        return;
      }

      // if (this.form2.get('plan')?.value == 'enterprise' || this.form2.get('plan')?.value == 'startup') {

        Swal.fire({
          title: 'Quase lá',
          html: 'Estamos processando seus dados...',
          timer: 5000,
          timerProgressBar: false,
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => { 
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            // enterprise - apenas manda e-mail
            this.step = 10;
            this.swiperRef?.nativeElement.swiper.slideTo(5);
            // scroll up
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
        })

        let body = {
          nome_completo: this.form1.get('nome_completo')?.value,
          bairro: this.form1p2.get('bairro')?.value,
          cep: this.form1p2.get('cep')?.value,
          cidade: this.form1p2.get('cidade')?.value,
          complemento: this.form1p2.get('complemento')?.value,
          cnpj: this.form1.get('cnpj')?.value,
          contato1: this.form1.get('contato1')?.value,
          contato2: this.form1.get('contato2')?.value,
          email: this.form1.get('email')?.value + '@' + this.website,
          estado: this.form1p2.get('estado')?.value,
          num_endereco: this.form1p2.get('num_endereco')?.value,
          razao_social: this.form1.get('razao_social')?.value,
          nome_assina: this.form1.get('nome_assina')?.value,
          email_assina: this.form1.get('email_assina')?.value,
          rua: this.form1p2.get('rua')?.value,
          site: this.form1.get('site')?.value,
          plano_id: this.plans.find((e: any) => e.chave == this.form2.get('plan')?.value).id,
        };
        this.http.post<any>(this.env + '/api/pagamento/emailPendente', body).subscribe({
          next: data => {
          },
          error: error => {
            console.error('There was an error!', error);
          }
        });

        let body2 = {
          empresa: "cyber",
          email: this.form1.get('email')?.value + '@' + this.website,
          documento: this.form1.get('cnpj')?.value,
          nome: this.form1.get('nome_completo')?.value,
          titulo: "https://www.qualitymanager.com.br/",
          dominio: 'https://qualitymanager.com.br/',
          site: "QualityManager",
        };
        let header = {
          headers: {
            'Api-Key': '4b442024dae228ab55ab5863b9342ca0'
          }
        };
        this.http.post<any>('https://app.lgpd.center/api/v1/cliente/consentimento-externo', body2, header).subscribe({
          next: data => {
          },
          error: error => {
            console.error('There was an error!', error);
          }
        });

        return;
      // } else {
        // normal - vai para o pagamento
      //   this.step = 4;
      //   this.swiperRef?.nativeElement.swiper.slideTo(4);
      //   window.scroll({
      //     top: 0,
      //     left: 0,
      //     behavior: 'smooth'
      //   });
      //   return;
      // }
    }

    // Step 4
    if (this.step == 4) {

      Swal.fire({
        title: 'Quase lá',
        html: 'Estamos processando seus dados...',
        timer: 5000,
        timerProgressBar: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => { 
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          this.step = 10;
          this.swiperRef?.nativeElement.swiper.slideTo(5);
          // scroll up
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }
      })

      return;
    }
  }

  cepChange() {
    let cep = this.form1p2.get('cep')?.value;
    this.loadingCEP = true;

    // this.http.get<any>('https://brasilapi.com.br/api/cep/v2/' + cep).subscribe({
    //   next: data => {
    //     this.form1p2.controls['bairro'].setValue(data.neighborhood);
    //     this.form1p2.controls['cidade'].setValue(data.city);
    //     this.form1p2.controls['estado'].setValue(data.state);
    //     this.form1p2.controls['rua'].setValue(data.street);
    //     this.loadingCEP = false;
    //   },
    //   error: error => {
    //     console.error('There was an error!', error);
    //     this.loadingCEP = false;
    //   }
    // })
  
    this.http.get<any>('https://viacep.com.br/ws/' + cep + '/json/').subscribe({
      next: data => {
        this.form1p2.controls['bairro'].setValue(data.bairro);
        this.form1p2.controls['cidade'].setValue(data.localidade);
        this.form1p2.controls['estado'].setValue(data.uf);
        this.form1p2.controls['rua'].setValue(data.logradouro);
        this.loadingCEP = false;
      },
      error: error => {
        console.error('There was an error!', error);
        this.loadingCEP = false;
      }
    })
  }

  getTotalValue(allowDiscount: boolean = true) {
    let value;

    let selectedPlanKey = this.form2.get('plan')?.value;
    let selectedPlan = this.plans.find((e: any) => e.chave == selectedPlanKey);
    let selectedAccessQtd = this.form2.get('qtd')?.value != '' ? this.form2.get('qtd')?.value : 1;

    let implementacaoValue = this.form2.controls.implementacao.value == 1 ? this.valorImplementacao : 0;

    if (selectedPlanKey == 'enterprise') {
      value = Number(0.00).toFixed(2);
    } else if (selectedPlanKey == 'startup') {
      value = Number(0.00).toFixed(2);
    } else {
      value = selectedPlan.valores_por_acesso.find((e: any) => e.quantidade_acessos == selectedAccessQtd).valor;
    }

    let periodoMeses = this.prazos.find((e: any) => e.id == this.form2.controls.renovacao.value)?.prazo ?? 1;

    value = Number(value) * Number(periodoMeses);

    // porcentage discount
    let selectedDesconto = this.prazos.find((e: any) => e.id == this.form2.controls.renovacao.value)?.desconto ?? 0;
    
    if (allowDiscount == true) {
      value = Number(value) - (Number(value) * Number(selectedDesconto) / 100);
    }

    value = Number(value) + Number(implementacaoValue);

    return value.toFixed(2);
  }

  genPix() {
    let body = {
      nome_completo: this.form1.get('nome_completo')?.value,
      bairro: this.form1p2.get('bairro')?.value,
      cep: this.form1p2.get('cep')?.value,
      cidade: this.form1p2.get('cidade')?.value,
      complemento: this.form1p2.get('complemento')?.value,
      cnpj: this.form1.get('cnpj')?.value,
      contato1: this.form1.get('contato1')?.value,
      contato2: this.form1.get('contato2')?.value,
      email: this.form1.get('email')?.value + '@' + this.website,
      estado: this.form1p2.get('estado')?.value,
      num_endereco: this.form1p2.get('num_endereco')?.value,
      razao_social: this.form1.get('razao_social')?.value,
      rua: this.form1p2.get('rua')?.value,
      site: this.form1.get('site')?.value,
      plano_id: this.plans.find((e: any) => e.chave == this.form2.get('plan')?.value).id,
      qte_acessos: this.form2.get('qtd')?.value,
      implementacao: this.form2.controls.implementacao.value == 1 ? true : false,
      assinatura_id: this.form2.controls.renovacao.value,
    };
    this.http.post<any>(this.env + '/api/pagamento/faturaPix', body).subscribe({
      next: data => {
        this.pixData = data?.pix_payment;
        this.pixData.fatura = data?.fatura_id;

        setInterval(() => {
          this.http.get<any>(this.env + '/api/pagamento/consultarStatus/' + data?.fatura_id).subscribe({
            next: data => {
              if (data?.response.status == 'PAID' || data?.response.status == 'RECEIVED') {

                    this.step = 10;
                    this.swiperRef?.nativeElement.swiper.slideTo(5);
                    // scroll up
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: 'smooth'
                    })

              }
            },
            error: error => {
              console.error('There was an error!', error);
            }
          })
        }, 5000);
      },
      error: error => {
        console.error('There was an error!', error);
      }
    })
  }

  finishPaymentCard() {

    this.errorPart4 = false;

    if (this.form4.get('card_number')?.value == '') {
      this.errorPart4 = true;
      this.errorPart4Message = 'O número do cartão é obrigatório.';
      setTimeout(() => {
        this.errorPart4 = false;
      }, 5000);
      return;
    }
    if (this.form4.get('card_name')?.value == '') {
      this.errorPart4 = true;
      this.errorPart4Message = 'O nome do titular é obrigatório.';
      setTimeout(() => {
        this.errorPart4 = false;
      }, 5000);
      return;
    }
    if (this.form4.get('card_month_year')?.value == '') {
      this.errorPart4 = true;
      this.errorPart4Message = 'A data de validade é obrigatória.';
      setTimeout(() => {
        this.errorPart4 = false;
      }, 5000);
      return;
    }
    if (this.form4.get('card_cvv')?.value == '') {
      this.errorPart4 = true;
      this.errorPart4Message = 'O código de segurança é obrigatório.';
      setTimeout(() => {
        this.errorPart4 = false;
      }, 5000);
      return;
    }
    if (this.form4.get('pay_type')?.value == '') {
      this.errorPart4 = true;
      this.errorPart4Message = 'O tipo de pagamento é obrigatório.';
      setTimeout(() => {
        this.errorPart4 = false;
      }, 5000);
      return;
    }

    let date_card = this.form4.get('card_month_year')?.value ?? '';
    // first 2 numbers are the month
    let month = date_card.substring(0, 2);
    // last 2 numbers are the year
    let year = date_card.substring(2, 4);

    let body = {
      nome_completo: this.form1.get('nome_completo')?.value,
      bairro: this.form1p2.get('bairro')?.value,
      cep: this.form1p2.get('cep')?.value,
      cidade: this.form1p2.get('cidade')?.value,
      complemento: this.form1p2.get('complemento')?.value,
      cnpj: this.form1.get('cnpj')?.value,
      contato1: this.form1.get('contato1')?.value,
      contato2: this.form1.get('contato2')?.value,
      email: this.form1.get('email')?.value + '@' + this.website,
      estado: this.form1p2.get('estado')?.value,
      num_endereco: this.form1p2.get('num_endereco')?.value,
      razao_social: this.form1.get('razao_social')?.value,
      rua: this.form1p2.get('rua')?.value,
      site: this.form1.get('site')?.value,
      plano_id: this.plans.find((e: any) => e.chave == this.form2.get('plan')?.value).id,
      qte_acessos: this.form2.get('qtd')?.value,

      implementacao: this.form2.controls.implementacao.value == 1 ? true : false,
      assinatura_id: this.form2.controls.renovacao.value,

      numero_cartao: this.form4.get('card_number')?.value,
      nome_cartao: this.form4.get('card_name')?.value,
      mes_validade: month,
      ano_validade: year,
      cvv: this.form4.get('card_cvv')?.value,
    };

    this.loadingCard = true;

    let pay_type = this.form4.controls.pay_type.value ?? 1;

    if (pay_type == 1) {
      this.http.post<any>(this.env + '/api/pagamento/faturaCartao', body).subscribe({
        next: data => {

          Swal.fire({
            title: 'Quase lá',
            html: 'Estamos processando seus dados...',
            timer: 5000,
            timerProgressBar: false,
            didOpen: () => {
              Swal.showLoading();
            },
            willClose: () => { 
            }
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              this.step = 10;
              this.swiperRef?.nativeElement.swiper.slideTo(5);
              // scroll up
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
              this.loadingCard = false;
            }
          })

        },
        error: error => {
          console.error('There was an error!', error);
          this.errorPart4 = true;
          this.errorPart4Message = 'Ocorreu um erro ao processar o pagamento. Verifique os dados e tente novamente.';
          this.loadingCard = false;
          setTimeout(() => {
            this.errorPart4 = false;
          }, 5000);
        }
      })
    }

    if (pay_type == 2) {
      this.http.post<any>(this.env + '/api/pagamento/assinaturaCartao', body).subscribe({
        next: data => {

          Swal.fire({
            title: 'Quase lá',
            html: 'Estamos processando seus dados...',
            timer: 5000,
            timerProgressBar: false,
            didOpen: () => {
              Swal.showLoading();
            },
            willClose: () => { 
            }
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              this.step = 10;
              this.swiperRef?.nativeElement.swiper.slideTo(5);
              // scroll up
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
              this.loadingCard = false;
            }
          })

        },
        error: error => {
          console.error('There was an error!', error);
          this.errorPart4 = true;
          this.errorPart4Message = 'Ocorreu um erro ao processar o pagamento. Verifique os dados e tente novamente.';
          this.loadingCard = false;
          setTimeout(() => {
            this.errorPart4 = false;
          }, 5000);
        }
      })
    }

  }

  getPixImage() {
    return 'data:image/png;base64,' + this.pixData?.encodedImage;
  }

  copy(link: string) {
    // copy to clipboard
    let aux = document.createElement("input");
    aux.setAttribute("value", link);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
  }

  validateCNPJ(internal: any) {
    let cnpj = internal;

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') {
      return false;
    }

    if (cnpj.length != 14) {
      return false;
    }

    /* Faz o calculo de validação do cnpj */

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;

      if (pos < 2) {
        pos = 9;
      }
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado != parseInt(digitos.charAt(0))) {
      return false;
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;

      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado != parseInt(digitos.charAt(1))) {
      return false;
    }

    return true;
  }

  getCreditCardLabel() {
    let cardNumber = this.form4.get('card_number')?.value ?? '';
    cardNumber = cardNumber.replace(/\s/g, '');

    var regexVisa = /^4[0-9]{12}(?:[0-9]{3})?/;
    var regexMaster = /^5[1-5][0-9]{14}/;
    var regexAmex = /^3[47][0-9]{13}/;
    var regexDiners = /^3(?:0[0-5]|[68][0-9])[0-9]{11}/;
    var regexDiscover = /^6(?:011|5[0-9]{2})[0-9]{12}/;
    var regexJCB = /^(?:2131|1800|35\d{3})\d{11}/;
    var regexElo = /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/;

    if(regexElo.test(cardNumber)){
      return 'elo';
    }
    if(regexVisa.test(cardNumber)){
     return 'visa';
    }
    if(regexMaster.test(cardNumber)){
     return 'master';
    }
    if(regexAmex.test(cardNumber)){
     return 'amex';
    }
    if(regexDiners.test(cardNumber)){
     return 'diners';
    }
    if(regexDiscover.test(cardNumber)){
     return 'discover';
    }
    if(regexJCB.test(cardNumber)){
     return 'jcb';
    }

    return 'Invalido';
  }

  getTextSize(string: any) {
    if (this.form1.controls.email.value == '') {
      return 100;
    }

    // font Poppins, size 14px
    let pxSize = 0.43;

    // size of each letter
    for (let i = 0; i < string.length; i++) {
      let letter = string[i];

      if (letter == 'm') {
        pxSize += 1.66;
      }

      if (letter == 'W') {
        pxSize += 1.56;
      }

      if (letter == 'M') {
        pxSize += 1.385;
      }

      if (letter == 'w') {
        pxSize += 1.316;
      }

      if (letter == 'O' || letter == 'Q') {
        pxSize += 1.265;
      }

      if (letter == 'C' || letter == 'G') {
        pxSize += 1.235;
      }

      if (letter == '_') {
        pxSize += 1.19;
      }

      if (letter == 'D' || letter == 'H') {
        pxSize += 1.135;
      }

      if (letter == 'N') {
        pxSize += 1.125;
      }

      if (letter == 'a' || letter == 'A' || letter == 'b' || letter == 'd' || letter == 'g' || letter == 'p' || letter == 'q' || letter == 'U' || letter == 'V') {
        pxSize += 1.08;
      }

      if (letter == 'h' || letter == 'n' || letter == 'u' || letter == 'o') {
        pxSize += 1.03;
      }

      if (letter == '4' || letter == '5' || letter == '6' || letter == '8' || letter == '9' || letter == '0') {
        pxSize += 1.01;
      }

      if (letter == 'e' || letter == 'X' || letter == '-') {
        pxSize += 0.99;
      }

      if (letter == 'B' || letter == 'c' || letter == 'K' || letter == 'R') {
        pxSize += 0.98;
      }

      if (letter == 'S' || letter == 'Y') {
        pxSize += 0.94;
      }

      if (letter == 'P' || letter == '2' || letter == '3') {
        pxSize += 0.935;
      }

      if (letter == 'v' || letter == 'y') {
        pxSize += 0.91;
      }

      if (letter == 'T' || letter == 'Z' || letter == '7') {
        pxSize += 0.87;
      }

      if (letter == 'J' || letter == 'k' || letter == 's') {
        pxSize += 0.855;
      }
      
      if (letter == 'E' || letter == 'F') {
        pxSize += 0.82;
      }

      if (letter == 'x') {
        pxSize += 0.765;
      }

      if (letter == 'z') {
        pxSize += 0.74;
      }

      if (letter == 'L') {
        pxSize += 0.695;
      }

      if (letter == 'r') {
        pxSize += 0.61;
      }

      if (letter == 't') {
        pxSize += 0.58;
      }

      if (letter == 'f' || letter == '1') {
        pxSize += 0.525;
      }

      if (letter == 'i' || letter == 'I' || letter == 'j' || letter == 'l') {
        pxSize += 0.395;
      }

      if (letter == '.') {
        pxSize += 0.335;
      }
    }

    return pxSize;
  }

  siteChange(e: any) {
    console.log(e.target.value);
    this.website = e.target.value;
    this.website = this.website.replace('https://', '');
    this.website = this.website.replace('http://', '');
    this.website = this.website.replace('www.', '');
    this.website = this.website.replace('/', '');
  }

  verifyCNPJ(cnpj: any) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') {
      return false;
    }

    if (cnpj.length != 14) {
      return false;
    }

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" || 
      cnpj == "11111111111111" || 
      cnpj == "22222222222222" || 
      cnpj == "33333333333333" || 
      cnpj == "44444444444444" || 
      cnpj == "55555555555555" || 
      cnpj == "66666666666666" || 
      cnpj == "77777777777777" || 
      cnpj == "88888888888888" || 
      cnpj == "99999999999999") {
      return false;
    }
    
    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += Number(numeros.charAt(tamanho - i)) * pos--;

      if (pos < 2) {
        pos = 9;
      }
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado != Number(digitos.charAt(0))) {
      return false;
    }
    
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += Number(numeros.charAt(tamanho - i)) * pos--;

      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado != Number(digitos.charAt(1))) {
      return false;
    }

    return true;
  }

}
